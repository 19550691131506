import axios from 'axios';

const auth = {
    namespaced: true,
    state: {
        status: '',
        token: localStorage.getItem('#') || '',       
        orgid: null,
        username: localStorage.getItem('###') || '',
        user: JSON.stringify(localStorage.getItem('####')),
        PwdPad: 'odHRwOi8vc2NoZW1hcy5taWNyb3NvZnQuY29tL3dzLzIwMDgvMDYvaWRlbnRpdHkvY2xhaW1zL3JvbGUiOiIwMSIsImh0dHA6Ly9zY2hlbWFzLnhtbHNvYXAub3JnL',
        UsPad: 'cvd3MvMjAwNS8wNS9pZGVudGl0eS9jbGFpbXMvc3VybmFtZSI6Ik1ha2VyIiwiaHR0cDovL3NjaGVtYXMubWljcm9zb2Z0LmNvbS93cy8yMDA4Lz',
        baseurl: process.env.VUE_APP_API_URL,
        accesskey: 'DxaGiSPm/CKRlSaPQnKCv3rQvV5Rj7MoPdHcZ79UvQg='
    },
    mutations: {
        auth_request(state) {
            state.status = 'loading';
        },
        auth_token(state, { token }) {
            state.status = 'success'
            localStorage.setItem("#", token);
            state.token = token
        },
        auth_success(state) {
            state.status = 'success'           
        },
        auth_username(state, username) {
            state.status = 'success'  
            localStorage.setItem('###',username) 
            state.username = username
        },
        auth_error(state) {
            state.status = 'error'
        },
        logout(state) {
            state.status = ''
            state.token = null
        },
        SAVE_USER_INFO (state, payload) {
            localStorage.setItem('####', payload)
            state.user=payload
          },
    },
    getters: {
        _userAuthToken: state => state.token,
        _user: state => state.user,
        _username:state => state.username,
        authStatus: state => state.status
    },
    actions: {
        Auth({ commit }, payload) {
            return new Promise((resolve, reject) => {
                commit('auth_request')              
                axios.post(`${process.env.VUE_APP_ENV_API_URL}PortalAuth/Auth`, payload)
                    .then(resp => resp.data)
                    .then(loginuser => {
                        if (loginuser.responseCode == '00') {
                            const token = loginuser.data.accessToken;
                            const userDetails=loginuser.data.data;                           
                            commit('SAVE_USER_INFO',userDetails )
                            commit('auth_token', { token });
                        }
                        resolve(loginuser)
                    })
                    .catch(err => {
                        commit('auth_error')
                        localStorage.removeItem('#')
                        reject(err)
                    })
            })
        },
        ChangeDefaultPassword({ commit }, payload) {
            return new Promise((resolve, reject) => {                   
                axios.post(`${process.env.VUE_APP_ENV_API_URL}PortalAuth/ChangeDefaultPassword`, payload)
                    .then(resp => resp.data)
                    .then(loginuser => {                       
                        resolve(loginuser)
                    })
                    .catch(err => {  
                        commit('auth_error')                     
                        reject(err)
                    })
            })
        },
        RetrievePassword({ commit }, Username) {
            return new Promise((resolve, reject) => {                   
                axios.get(`${process.env.VUE_APP_ENV_API_URL}PortalAuth/RetrievePassword?Username=${Username}`)
                    .then(resp => resp.data)
                    .then(loginuser => {                       
                        resolve(loginuser)
                    })
                    .catch(err => {  
                        commit('auth_error')                     
                        reject(err)
                    })
            })
        },
        ValidateToken({ commit, rootState }) {
            return new Promise((resolve, reject) => {
                const headers = {
                    Authorization: `bearer ${rootState.auth.token}`
                };
                commit('auth_request')
                axios.get(`${process.env.VUE_APP_ENV_API_URL}PortalAuth/ValidateToken`, { headers })
                    .then(resp => resp.data)
                    .then(loginuser => {
                        resolve(loginuser)
                    })
                    .catch(err => {
                        commit('auth_error')
                        localStorage.removeItem('token')
                        reject(err)
                    })
            })
        },
        AuthenticateUser({ commit }, accessToken) {
            return new Promise((resolve, reject) => {

                const headers = { Authorization: `bearer ${accessToken}` };
                commit('auth_request')
                axios.get(`${process.env.VUE_APP_ENV_API_URL}PortalAuth/ValidateToken`, { headers })
                    .then(resp => resp.data)
                    .then(loginuser => {
                        resolve(loginuser)
                    })
                    .catch(err => {
                        commit('auth_error')
                        localStorage.removeItem('#')
                        reject(err)
                    })
            })
        },       
        MutateUsername({ commit }, username) {
            commit('auth_username', username);
        },
        Logout({ commit }) {
            return new Promise((resolve) => {
                commit('logout')
                localStorage.removeItem('#')
                delete axios.defaults.headers.common['Authorization']
                resolve()
            })
        }
    },
}

export default auth