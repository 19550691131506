import axios from 'axios';

const SchoolType = {
    namespaced: true,    
    actions: {
        GetSchoolTypeForPortal({ rootState }) {
            return new Promise((resolve, reject) => {  
                const headers = {
                    Authorization: `bearer ${rootState.auth.token}`
                };                    
                axios.get(`${process.env.VUE_APP_ENV_API_URL}SchoolTypes/GetSchoolTypeForPortal`, { headers })
                    .then(resp => resp.data)
                    .then(data => {                       
                        resolve(data)
                    })
                    .catch(err => {                       
                        reject(err)
                    })
            })
        },           
    },
}

export default SchoolType