import axios from 'axios';
const grade = {
    state: { 		
		gradescales : []
	},	
	mutations: {		
		grade_success(state, payload){			
			state.gradescales = payload	
		}		
	},
	actions: {        
		getgradescale({commit, rootState}, CatId){
			return new Promise((resolve, reject) => {				
				axios({url: '/Gradescale/Getgradescale', data: {id: CatId, searchitem: null, username : rootState.auth.username, accesskey : rootState.auth.accesskey}, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {
					const payload = respdata;					
					commit('grade_success',payload);					
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
		},				
		getgradescalebyid({rootState},Id){
			return new Promise((resolve, reject) => {				
				axios({url: '/Gradescale/Getgradescalebyid', data: {id: Id, searchitem: null, username : rootState.auth.username, accesskey : rootState.auth.accesskey}, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {									
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
		},		
		gradescaleregistration({commit}, userdata){
			return new Promise((resolve, reject) => {				
				axios({url: '/Gradescale/RegisterGrade', data: userdata, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {
					const payload = respdata;					
					commit('skill_success',payload);					
					resolve(respdata)
				})
				.catch(err => {
					reject(err)
				})
			})
		}
	}
}

export default grade