<template >
  <side-bar :background-color="sidebarBackground" short-title="SchoolDock" title="SchoolDock"
  v-if="accessDetails && staffDetails">
    <template slot="links" v-if="staffDetails">
      <sidebar-item :link="{
      name: 'Dashboard',
      icon: 'fa fa-tv text-primary',
      path: '/dashboard',
    }" />
      <sidebar-item :link="{
      name: 'Registrar',
      icon: 'fa fa-id-card text-blue',
      path: '/school/registrar',
    }" v-if="staffDetails.isRegistrar == true" />
      <sidebar-item :link="{
      name: 'Academic',
      icon: 'fa fa-chalkboard-teacher text-blue',
      path: '/school/academic',
    }" />
      <sidebar-item :link="{
      name: 'Student Record',
      icon: 'fa fa-clipboard text-blue',
      path: '/school/student-record',
    }" />
      <sidebar-item :link="{
      name: 'Hostel',
      icon: 'fa fa-bed text-blue',
      path: '/school/hostel',
    }" v-if="staffDetails.isHostelMaster == true" />
      <sidebar-item :link="{
      name: 'Library',
      icon: 'fa fa-book-reader text-blue',
      path: '/school/library',
    }" v-if="staffDetails.isLibraryAdmin == true" />
      <sidebar-item :link="{
      name: 'Finance',
      icon: 'fa fa-cash-register text-blue',
      path: '/school/finance',
    }" v-if="staffDetails.isBursal == true" />
      <sidebar-item :link="{
      name: 'Notification',
      icon: 'fa fa-bell text-blue',
      path: '/school/notifications',
    }" v-if="staffDetails.isPortalAdmin == true" />
      <sidebar-item :link="{
      name: 'Chat',
      icon: 'fab fa-rocketchat text-blue',
      path: '/school/notifications',
    }" v-if="staffDetails.isPortalAdmin == true" />
      <sidebar-item :link="{
      name: 'SMS',
      icon: 'fas fa-sms text-blue',
      path: '/school/sms-sender',
    }" v-if="staffDetails.isPortalAdmin == true" />
      <sidebar-item :link="{
      name: 'Website',
      icon: 'fa fa-window-restore text-blue',
      path: '/school/website-setup',
    }" v-if="staffDetails.isPortalAdmin == true" />
      <sidebar-item :link="{
      name: 'Report',
      icon: 'fa fa-chart-bar text-blue',
      path: '/school/report',
    }" />

      <sidebar-item :link="{
      name: 'Setting',
      icon: 'fa fa-sliders-h text-blue',
      path: '/school/environment-settings',
    }" v-if="staffDetails.isPortalAdmin == true" />

      <sidebar-item :link="{
      name: 'Subscription',
      icon: 'fa fa-money-check text-blue',
      path: '/school/subscription',
    }" v-if="staffDetails.isPortalAdmin == true" />
      <sidebar-item :link="{
      name: 'Profile',
      icon: 'fa fa-user text-blue',
      path: '/user-profile',
    }" />
      <sidebar-item :link="{
      name: 'Help',
      icon: 'fa fa-question text-blue',
      path: '/dashboard',
    }" />
    </template>
  </side-bar>
</template>
<script>
export default {
  data() {
    return {
      sidebarBackground: "vue", //vue|blue|orange|green|red|primary
      accessDetails: null,
      staffDetails: null,
      onloader: "none",
    };
  },
  mounted() {
        this.accessDetails = this.$store.getters['auth/_user'];
        this.staffDetails = this.$store.getters['SchoolStaff/_schoolStaff'];
    },
};
</script>