import axios from 'axios';

const NewsSetup = {
    namespaced: true,    
    actions: {
        GetSchoolsForPortal({ rootState },SchoolTypeId) {
            return new Promise((resolve, reject) => {  
                const headers = {
                    Authorization: `bearer ${rootState.auth.token}`
                };                    
                axios.get(`${process.env.VUE_APP_ENV_API_URL}School/GetSchoolsForPortal?SchoolTypeId=${SchoolTypeId}`, { headers })
                    .then(resp => resp.data)
                    .then(data => {                       
                        resolve(data)
                    })
                    .catch(err => {                       
                        reject(err)
                    })
            })
        }, 
        GetSchoolsByIdForPortal({ rootState },SchoolId) {
            return new Promise((resolve, reject) => {  
                const headers = {
                    Authorization: `bearer ${rootState.auth.token}`
                };                    
                axios.get(`${process.env.VUE_APP_ENV_API_URL}School/GetSchoolsByIdForPortal?SchoolId=${SchoolId}`, { headers })
                    .then(resp => resp.data)
                    .then(data => {                       
                        resolve(data)
                    })
                    .catch(err => {                       
                        reject(err)
                    })
            })
        },           
    },
}

export default NewsSetup