import axios from 'axios';

const skill = {
    state: { 		
		skills : []
	},	
	mutations: {		
		skill_success(state, payload){			
			state.skills = payload	
		}		
	},
	actions: {        
		getskill({commit, rootState}){
			return new Promise((resolve, reject) => {				
				axios({url: '/Psycomotor/Getskill', data: {id: '1', searchitem: null, username : rootState.auth.username, accesskey : rootState.auth.accesskey}, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {
					const payload = respdata;					
					commit('skill_success',payload);					
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
		},
		getskillsearch({commit, rootState}, item){
			return new Promise((resolve, reject) => {				
				axios({url: '/Psycomotor/Getskillsearch', data: {id: '1', searchitem: item, username : rootState.auth.username, accesskey : rootState.auth.accesskey}, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {
					const payload = respdata;					
					commit('skill_success',payload);					
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
		},		
		getskillbyid({rootState},Id){
			return new Promise((resolve, reject) => {				
				axios({url: '/Psycomotor/Getskillbyid', data: {id: Id, searchitem: null, username : rootState.auth.username, accesskey : rootState.auth.accesskey}, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {									
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
		},		
		skillregistration({commit}, userdata){
			return new Promise((resolve, reject) => {				
				axios({url: '/Psycomotor/Registerskill', data: userdata, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {
					const payload = respdata;					
					commit('skill_success',payload);					
					resolve(respdata)
				})
				.catch(err => {
					reject(err)
				})
			})
		}
	}
}

export default skill