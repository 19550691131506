import axios from 'axios';
const uploader = {
   
	actions: {
        uploadfile(context,formData){
			return new Promise((resolve, reject) => {          	
				axios.post('/Fileuploader/Uploadschoolfile',formData,{headers: {'Content-Type': 'multipart/form-data'}})
				.then(resp => resp.data)
				.then(respdata => {									
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
		},	
		uploadadminfile(context,formData){
			return new Promise((resolve, reject) => {          	
				axios.post('/Fileuploader/Uploadadminfile',formData,{headers: {'Content-Type': 'multipart/form-data'}})
				.then(resp => resp.data)
				.then(respdata => {									
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
		},		
		
	}
}

export default uploader