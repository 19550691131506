import axios from 'axios';

const hostelRoom = {
    namespaced: true,
    
	actions: {		
		ProcessHostelRoom(context, userdata){
			return new Promise((resolve, reject) => {				
				axios({url: '/HostelRoom/ProcessHostelRoom', data: userdata, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {									
					resolve(respdata)
				})
				.catch(err => {
					reject(err)
				})
			})
		}, 		
		GetHostelRoom({rootState},FloorId){
			return new Promise((resolve, reject) => {				
				axios.get(`/HostelRoom/GetHostelRoom?FloorId=${FloorId}&Username=${rootState.auth.username}&Accesskey=${rootState.auth.accesskey}`)
				.then(resp => resp.data)
				.then(respdata => {									
					resolve(respdata)
				})
				.catch(err => {
					reject(err)
				})
			})
		}, 
		GetByIdHostelRoom({rootState},{FloorId,Id}){
			return new Promise((resolve, reject) => {				
				axios.get(`/HostelRoom/GetByIdHostelRoom?Id=${Id}&FloorId=${FloorId}&Username=${rootState.auth.username}&Accesskey=${rootState.auth.accesskey}`)
				.then(resp => resp.data)
				.then(respdata => {									
					resolve(respdata)
				})
				.catch(err => {
					reject(err)
				})
			})
		}
	}
}

export default hostelRoom