import Vue from 'vue'
import App from './App.vue'
import router from '@/routers'
import './registerServiceWorker'
import store from './store/index'
import axios from 'axios'
import moment from 'moment'
import AOS from 'aos'
import 'aos/dist/aos.css'
import SDockDashboard from './plugins/sdock-dashboard'

Vue.prototype.$http = axios;
axios.defaults.baseURL = `${process.env.VUE_APP_ENV_API_URL_LIVE}`;


Vue.config.productionTip = false
Vue.filter('formatDate', function (value) {
  if (value) {
    return moment(String(value)).format('DD/MM/YYYY')
  }
});

Vue.use(SDockDashboard)
new Vue({
  router,
  store,
  AOS,
  render: h => h(App)
}).$mount('#app')
