import axios from 'axios';

const libraryBookStock = {
    namespaced: true,
    
	actions: {		
		ProcessLibraryBookStock(context, userdata){
			return new Promise((resolve, reject) => {				
				axios({url: '/LibraryBookStock/ProcessLibraryBookStock', data: userdata, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {									
					resolve(respdata)
				})
				.catch(err => {
					reject(err)
				})
			})
		}, 		
		GetLibraryBookStock({rootState}){
			return new Promise((resolve, reject) => {				
				axios.get(`/LibraryBookStock/GetLibraryBookStock?Username=${rootState.auth.username}&Accesskey=${rootState.auth.accesskey}`)
				.then(resp => resp.data)
				.then(respdata => {									
					resolve(respdata)
				})
				.catch(err => {
					reject(err)
				})
			})
		}, 
		GetByIdLibraryBookStock({rootState}, Id){
			return new Promise((resolve, reject) => {				
				axios.get(`/LibraryBookStock/GetByIdLibraryBookStock?Id=${Id}&Username=${rootState.auth.username}&Accesskey=${rootState.auth.accesskey}`)
				.then(resp => resp.data)
				.then(respdata => {									
					resolve(respdata)
				})
				.catch(err => {
					reject(err)
				})
			})
		},
        GetLibraryBookStockSummary({rootState}){
			return new Promise((resolve, reject) => {				
				axios.get(`/LibraryBookStock/GetLibraryBookStockSummary?Username=${rootState.auth.username}&Accesskey=${rootState.auth.accesskey}`)
				.then(resp => resp.data)
				.then(respdata => {									
					resolve(respdata)
				})
				.catch(err => {
					reject(err)
				})
			})
		}, 
	}
}

export default libraryBookStock