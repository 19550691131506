import axios from 'axios';

const term = {
    state: {  
		registerdterm : [],
		schsessions : [],
		schterm : []
	},	
	mutations: {		
		registeredterm_success(state, payload){			
			state.registerdterm = payload	
		},
		session_success(state, payload){			
			state.schsessions = payload	
		},
		term_success(state, payload){			
			state.schterm = payload	
		}
		
	},
	actions: {
		getregisteredterm({commit, rootState}){
			return new Promise((resolve, reject) => {				
				axios({url: '/Sessionset/Getregisteredterm', data: {id: '1', searchitem: null, username : rootState.auth.username, accesskey : rootState.auth.accesskey}, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {
					const registerdterm = respdata;					
					commit('registeredterm_success',registerdterm);					
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
		},
		getsessionforstudentreport({rootState},StudId){
			return new Promise((resolve, reject) => {				
				axios({url: '/Sessionset/Getsessionforstudentreport', data: {id: StudId, searchitem: null, username : rootState.auth.username, accesskey : rootState.auth.accesskey}, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {									
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
		},
		getsessionforstudentreportmid({rootState},StudId){
			return new Promise((resolve, reject) => {				
				axios({url: '/Sessionset/Getsessionforstudentreportmid', data: {id:StudId, searchitem: null, username : rootState.auth.username, accesskey : rootState.auth.accesskey}, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {									
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
		},
		gettermforstudentreport({rootState}, {StudId,SessionId}){
			return new Promise((resolve, reject) => {				
				axios({url: '/Sessionset/Gettermforstudentreport', data: {id:StudId, idd: SessionId, searchitem: null, username : rootState.auth.username, accesskey : rootState.auth.accesskey}, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {									
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
		},
		gettermforstudentreportmid({rootState}, {StudId,SessionId}){
			return new Promise((resolve, reject) => {				
				axios({url: '/Sessionset/Gettermforstudentreportmid', data: {id:StudId, idd: SessionId, searchitem: null, username : rootState.auth.username, accesskey : rootState.auth.accesskey}, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {									
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
		},
		getcurrentterm({rootState}){
			return new Promise((resolve, reject) => {				
				axios({url: '/Sessionset/Getcurrentterm', data: {id: '1', searchitem: null, username : rootState.auth.username, accesskey : rootState.auth.accesskey}, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {									
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
		},
		getregisteredtermbyid({rootState},Id){
			return new Promise((resolve, reject) => {				
				axios({url: '/Sessionset/Getbyid', data: {id: Id, searchitem: null, username : rootState.auth.username, accesskey : rootState.auth.accesskey}, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {									
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
		},
		getsession({commit, rootState}){
			return new Promise((resolve, reject) => {				
				axios({url: '/Sessionset/Getsession', data: {id: '1', searchitem: null, username : rootState.auth.username, accesskey : rootState.auth.accesskey}, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {
					const payload = respdata;					
					commit('session_success', payload);					
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
		},
		getregsession({rootState}){
			return new Promise((resolve, reject) => {				
				axios({url: '/Sessionset/Getregsession', data: {id: '1', searchitem: null, username : rootState.auth.username, accesskey : rootState.auth.accesskey}, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {
								
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
		},
		getsessionbyid({rootState},Id){
			return new Promise((resolve, reject) => {				
				axios({url: '/Sessionset/Getsessionbyid', data: {id: Id, searchitem: null, username : rootState.auth.username, accesskey : rootState.auth.accesskey}, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {									
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
		},
		GetSessionInSchoolBill({rootState}){
			return new Promise((resolve, reject) => {				
				axios.get(`/Sessionset/GetSessionInSchoolBill?Username=${rootState.auth.username}&Accesskey=${rootState.auth.accesskey}`)
				.then(resp => resp.data)
				.then(respdata => {									
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
        },
		getterm({commit, rootState}){
			return new Promise((resolve, reject) => {				
				axios({url: '/Sessionset/Getterm', data: {id: '1', searchitem: null, username : rootState.auth.username, accesskey : rootState.auth.accesskey}, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {
					const payload = respdata;					
					commit('term_success', payload);					
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
		},
		GettermInSchoolBill({rootState},SessionId){
			return new Promise((resolve, reject) => {				
				axios.get(`/Sessionset/GettermInSchoolBill?SessionId=${SessionId}&Username=${rootState.auth.username}&Accesskey=${rootState.auth.accesskey}`)
				.then(resp => resp.data)
				.then(respdata => {									
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
        },
		getregterm({rootState},Id){
			return new Promise((resolve, reject) => {				
				axios({url: '/Sessionset/Getregterm', data: {id: Id, searchitem: null, username : rootState.auth.username, accesskey : rootState.auth.accesskey}, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {
									
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
		},
		termregistration({commit}, userdata){
			return new Promise((resolve, reject) => {				
				axios({url: '/Sessionset/Submitterm', data: userdata, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {
					const registerdterm = respdata;					
					commit('registeredterm_success',registerdterm);					
					resolve(respdata)
				})
				.catch(err => {
					reject(err)
				})
			})
		},
		sessionregistration({commit}, userdata){
			return new Promise((resolve, reject) => {				
				axios({url: '/Sessionset/Submitsession', data: userdata, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {
					const payload = respdata;					
					commit('session_success', payload);					
					resolve(respdata)
				})
				.catch(err => {
					reject(err)
				})
			})
		}
	}
}

export default term