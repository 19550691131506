<template>
  <side-bar :background-color="sidebarBackground" short-title="SchoolDock" title="SchoolDock">
    <template slot="links">
      <sidebar-item :link="{
    name: 'Dashboard', icon: 'ni ni-tv-2 text-primary', path: '/dashboard'
  }" />
      <sidebar-item :link="{ name: 'Class Note', icon: 'fa fa-book text-blue', path: '/dashboard' }" />
      <sidebar-item :link="{ name: 'CBT', icon: 'ni ni-app text-blue', path: '/student/exam' }" />

      <sidebar-item :link="{ name: 'Home Work', icon: 'ni ni-ruler-pencil text-blue', path: '/student/home-work' }" />
      <sidebar-item :link="{ name: 'Archievement', icon: 'ni ni-trophy text-blue', path: '/student/achievement' }" />
      <sidebar-item
        :link="{ name: 'Communication Book', icon: 'ni ni-notification-70 text-blue', path: '/student/communication-book' }" />

      <sidebar-item :link="{ name: 'Report Card', icon: 'ni ni-chart-bar-32 text-blue', path: '/student/report-card' }" />

    </template>
  </side-bar>
</template>
<script>
export default {
  data() {
    return {
      sidebarBackground: 'vue' //vue|blue|orange|green|red|primary
    };
  },
}
</script>
