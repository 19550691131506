<template>
  <div id="loaderoverlay" v-bind:style="{ display: overlay }">
    <div class="w-100 d-flex justify-content-center align-items-center">
      <img src="/img/brand/no_internet.gif" class="img-fluid" />
      <div class="loaderspinner"></div>
    </div>
  </div>
</template>
<script>
export default {
  name: "InternetAlert",
  data() {
    return {
      onLine: navigator.onLine,
      showBackOnline: false,
      overlay: "none",
    };
  },
  methods: {
    updateOnlineStatus(e) {
      const { type } = e;
      this.onLine = type === "online";     
      this.showBackOnline = false;
      if (this.onLine == true) {
        this.overlay = "none";
      }
      if (this.onLine == false) {
        this.overlay = "flex";
      }
    },
  },
  watch: {
    onLine(v) {
      if (v) {
        this.showBackOnline = true;
        setTimeout(() => {}, 100);
      }
    },
  },
  mounted() {
    window.addEventListener("online", this.updateOnlineStatus);
    window.addEventListener("offline", this.updateOnlineStatus);
  },
  beforeDestroy() {
    window.removeEventListener("online", this.updateOnlineStatus);
    window.removeEventListener("offline", this.updateOnlineStatus);
  },
};
</script>
