import axios from 'axios';

const reportcard = {
    
	actions: {
		getreport({rootState}, {SessionId,TermId,StudId}){
			return new Promise((resolve, reject) => {				
				axios.get(`/Reportcard/Getreportcard?SessionId=${SessionId}&TermId=${TermId}&StudId=${StudId}&Username=${rootState.auth.username}&Accesskey=${rootState.auth.accesskey}`)
				.then(resp => resp.data)
				.then(respdata => {						
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
		},        
		getheadteacherrepcomment(context, userdata){
			return new Promise((resolve, reject) => {				
				axios({url: '/Reportcard/Getheadteacherrepcomment', data: userdata, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {									
					resolve(respdata)
				})
				.catch(err => {
					reject(err)
				})
			})
		},    
		processheadteacherrepcomment(context, userdata){
			return new Promise((resolve, reject) => {				
				axios({url: '/Reportcard/Processheadteacherrepcomment', data: userdata, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {									
					resolve(respdata)
				})
				.catch(err => {
					reject(err)
				})
			})
		},  
		getclassteacherrepcomment(context, userdata){
			return new Promise((resolve, reject) => {				
				axios({url: '/Reportcard/Getclassteacherrepcomment', data: userdata, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {									
					resolve(respdata)
				})
				.catch(err => {
					reject(err)
				})
			})
		}, 
		processclassteacherrepcomment(context, userdata){
			return new Promise((resolve, reject) => {				
				axios({url: '/Reportcard/Processclassteacherrepcomment', data: userdata, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {									
					resolve(respdata)
				})
				.catch(err => {
					reject(err)
				})
			})
		},  
		sendreport({rootState}, {SessionId,TermId,StudId}){
			return new Promise((resolve, reject) => {				
				axios.get(`/Reportcard/Sendreport?SessionId=${SessionId}&TermId=${TermId}&StudId=${StudId}&Username=${rootState.auth.username}&Accesskey=${rootState.auth.accesskey}`)
				.then(resp => resp.data)
				.then(respdata => {									
					resolve(respdata)
				})
				.catch(err => {
					reject(err)
				})
			})
		}, 
		GetAllGeneratedReportFile({rootState}, {PageNumber,PageSize,SearchValue}){
			return new Promise((resolve, reject) => {	
				const headers = {
                    Authorization: `bearer ${rootState.auth.token}`
                };			
				axios.get(`${process.env.VUE_APP_ENV_API_URL}PortalReport/GetAllGeneratedReportFile?PageNumber=${PageNumber}&PageSize=${PageSize}&SearchValue=${SearchValue}`, { headers })
				.then(resp => resp.data)
				.then(respdata => {						
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
		},  
		getbroadsheet({rootState}, {SessionId,TermId,ClassId,OptionId}){
			return new Promise((resolve, reject) => {	
				const headers = {
                    Authorization: `bearer ${rootState.auth.token}`
                };			
				axios.get(`${process.env.VUE_APP_ENV_API_URL}PortalReport/Broadsheet?SessionId=${SessionId}&TermId=${TermId}&ClassId=${ClassId}&OptionId=${OptionId}`, { headers })
				.then(resp => resp.data)
				.then(respdata => {						
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
		},  
		getbroadsheetperposition({rootState}, {SessionId,TermId,ClassId,OptionId}){
			return new Promise((resolve, reject) => {				
				axios.get(`/Reportcard/GetbroadsheetPerPosition?SessionId=${SessionId}&TermId=${TermId}&ClassId=${ClassId}&OptionId=${OptionId}&Username=${rootState.auth.username}&Accesskey=${rootState.auth.accesskey}`)
				.then(resp => resp.data)
				.then(respdata => {						
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
		}, 
		getreportcardbypasscode({rootState},passcode){
			return new Promise((resolve, reject) => {				
				axios({url: '/Reportcard/Getreportcardbypasscode', data: {id: 1, searchitem: passcode, username : rootState.auth.username, accesskey : rootState.auth.accesskey}, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {						
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
		},          
	}
}

export default reportcard