const studentRoutes = [
    {
        path: '/user-profile',
        name: 'User Profile',
        component: () => import('@/views/UserProfile.vue'),
        meta: { user: ['Administrator', 'SchoolStaff', 'Student'] }
    },
    {
        path: '/student/exam',
        name: 'Student Exam',
        component: () => import('@/views/PortalStudent/CBT/Index.vue'),
        meta: { user: 'Student' }
    },
    {
        path: '/student/exam-questions',
        name: 'Student Exam Questions',
        component: () => import('@/views/PortalStudent/CBT/Component/Exam.vue'),
        meta: { user: 'Student' }
    },

    {
        path: '/student/report-card',
        name: 'Student Report Card',
        component: () => import('@/views/PortalStudent/Report/Index.vue'),
        meta: { user: 'Student' }
    },
    {
        path: '/student/report-card-full-term',
        name: 'Student Report Card Full Term',
        component: () => import('@/views/PortalStudent/Report/Component/Reportcard.vue'),
        meta: { user: 'Student' }
    },
    {
        path: '/student/report-card-mid-term',
        name: 'Student Report Card Mid Term',
        component: () => import('@/views/PortalStudent/Report/Component/Reportcardmid.vue'),
        meta: { user: 'Student' }
    },
    {
        path: '/student/home-work',
        name: 'Student Home Work',
        component: () =>
            import('@/views/PortalStudent/Homework.vue'),
        meta: { user: 'Student' }
    },
    {
        path: '/student/home-work-answer',
        name: 'Student Home Work Answer',
        component: () =>
            import('@/views/PortalStudent/Homeworkanswer.vue'),
        meta: { user: 'Student' }
    },
    {
        path: '/student/achievement',
        name: 'Student Achievement',
        component: () =>
            import('@/views/PortalStudent/Studentarchievement.vue'),
        meta: { user: 'Student' }
    },
    {
        path: '/student/communication-book',
        name: 'Student Communication Book',
        component: () =>
            import('@/views/PortalStudent/Noteonstudent.vue'),
        meta: { user: 'Student' }
    },
    {
        path: '/student/exam-prep',
        name: 'Student Exam Prep',
        component: () =>
            import('@/views/PortalStudent/CBT/Component/Cbtprep.vue'),
        meta: { user: 'Student' }
    },
    {
        path: '/student/exam-prep-subjects',
        name: 'Student Exam Prep Subjects',
        component: () =>
            import('@/views/PortalStudent/CBT/Component/Cbtexamsubject.vue'),
        meta: { user: 'Student' }
    },
    {
        path: '/student/exam-prep-result-summary',
        name: 'Student Exam Prep Result Summary',
        component: () =>
            import('@/views/PortalStudent/CBT/Component/CbtSummary.vue'),
        meta: { user: 'Student' }
    },
    {
        path: '/student/exam-prep-result-details',
        name: 'Student Exam Prep Result Details',
        component: () =>
            import('@/views/PortalStudent/CBT/Component/CbtResultDetails.vue'),
        meta: { user: 'Student' }
    },

]

export default studentRoutes
