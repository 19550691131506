import axios from 'axios';

const conduct = {
    state: { 		
		conducts : []
	},	
	mutations: {		
		conduct_success(state, payload){			
			state.conducts = payload	
		}		
	},
	actions: {        
		getconduct({commit, rootState}){
			return new Promise((resolve, reject) => {				
				axios({url: '/Conduct/Getconduct', data: {id: '1', searchitem: null, username : rootState.auth.username, accesskey : rootState.auth.accesskey}, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {
					const payload = respdata;					
					commit('conduct_success',payload);					
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
		},
		getconductsearch({commit, rootState}, item){
			return new Promise((resolve, reject) => {				
				axios({url: '/Conduct/Getconductsearch', data: {id: '1', searchitem: item, username : rootState.auth.username, accesskey : rootState.auth.accesskey}, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {
					const payload = respdata;					
					commit('conduct_success',payload);					
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
		},		
		getconductbyid({rootState},Id){
			return new Promise((resolve, reject) => {				
				axios({url: '/Conduct/Getconductbyid', data: {id: Id, searchitem: null, username : rootState.auth.username, accesskey : rootState.auth.accesskey}, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {									
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
		},		
		conductregistration({commit}, userdata){
			return new Promise((resolve, reject) => {				
				axios({url: '/Conduct/Registerconduct', data: userdata, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {
					const payload = respdata;					
					commit('conduct_success',payload);					
					resolve(respdata)
				})
				.catch(err => {
					reject(err)
				})
			})
		}
	}
}

export default conduct