import axios from 'axios';

const hostelStudentBed = {
    namespaced: true,
    
	actions: {		
		ProcessHostelStudentBed(context, userdata){
			return new Promise((resolve, reject) => {				
				axios({url: '/HostelStudentBed/ProcessHostelStudentBed', data: userdata, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {									
					resolve(respdata)
				})
				.catch(err => {
					reject(err)
				})
			})
		}, 		
		GetHostelStudentBed({rootState}){
			return new Promise((resolve, reject) => {				
				axios.get(`/HostelStudentBed/GetHostelStudentBed?Username=${rootState.auth.username}&Accesskey=${rootState.auth.accesskey}`)
				.then(resp => resp.data)
				.then(respdata => {									
					resolve(respdata)
				})
				.catch(err => {
					reject(err)
				})
			})
		}, 
		GetByIdHostelStudentBed({rootState},Id){
			return new Promise((resolve, reject) => {				
				axios.get(`/HostelStudentBed/GetByIdHostelStudentBed?Id=${Id}&Username=${rootState.auth.username}&Accesskey=${rootState.auth.accesskey}`)
				.then(resp => resp.data)
				.then(respdata => {									
					resolve(respdata)
				})
				.catch(err => {
					reject(err)
				})
			})
		},
        RemoveStudentHostelBed({rootState},Id){
			return new Promise((resolve, reject) => {				
				axios.get(`/HostelStudentBed/RemoveStudentHostelBed?Id=${Id}&Username=${rootState.auth.username}&Accesskey=${rootState.auth.accesskey}, { crossdomain:true }`)
				.then(resp => resp.data)
				.then(respdata => {									
					resolve(respdata)
				})
				.catch(err => {
					reject(err)
				})
			})
		}
	}
}

export default hostelStudentBed