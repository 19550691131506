import axios from 'axios';

const usersdata = {
    state: { 	
		userStatus:[],	
		sexs : [],
		maritalstatuss : [],
		staffdetails:null
	},	
	mutations: {		
		sexs_success(state, payload){			
			state.sexs = payload	
		},
		maritalstatuss_success(state, payload){			
			state.maritalstatuss = payload	
		},
		staffdetails_success(state, payload){			
			state.staffdetails = payload	
		},
		userStatus_success(state, payload){			
			state.userStatus = payload	
		}
	},
	actions: {  
		getuser({commit,rootState}){
			return new Promise((resolve, reject) => {				
				axios.get(`/Userinfo/Getuser?Username=${rootState.auth.username}&accesskey=${rootState.auth.accesskey}`)
				.then(resp => resp.data)
				.then(respdata => {	
					const token = respdata.UserName;
					const user_fullName = respdata.FullName
					const user_phone = respdata.PhoneNo;
					const user_email = respdata.EmailAddress;
					const org_id=respdata.OrgId;
					const user_status_id = respdata.Status_Id;
					let user_passport = null;
					if(respdata.Passport!=null){
						user_passport=	respdata.Passport
					}	
					commit('auth_success',{token, user_fullName, user_phone, user_email, user_passport, user_status_id,org_id});							
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
		},	      
		getsex({commit, rootState}){
			return new Promise((resolve, reject) => {				
				axios.get(`/Userinfo/Getsex?Username=${rootState.auth.username}&accesskey=${rootState.auth.accesskey}`)
				.then(resp => resp.data)
				.then(respdata => {
					const payload = respdata;					
					commit('sexs_success',payload);					
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
		},					
		getmaritalstatus({commit, rootState}){
			return new Promise((resolve, reject) => {				
				axios.get(`/Userinfo/Getmaritalstatus?Username=${rootState.auth.username}&accesskey=${rootState.auth.accesskey}`)
				.then(resp => resp.data)
				.then(respdata => {
					const payload = respdata;					
					commit('maritalstatuss_success',payload);					
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
		},
		getstaffdetails({commit, rootState}){
			return new Promise((resolve, reject) => {				
				axios.get(`/Userinfo/Getstaffdetails?Username=${rootState.auth.username}&accesskey=${rootState.auth.accesskey}`)
				.then(resp => resp.data)
				.then(respdata => {
					const payload = respdata;					
					commit('staffdetails_success',payload);					
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
		},
		GetuserBySchool({rootState}){
			return new Promise((resolve, reject) => {				
				axios.get(`/Userinfo/GetuserBySchool?Username=${rootState.auth.username}&Accesskey=${rootState.auth.accesskey}`)
				.then(resp => resp.data)
				.then(respdata => {				
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
		},
		GetUserById({rootState}, Id){
			return new Promise((resolve, reject) => {				
				axios.get(`/Userinfo/GetUserById?Id=${Id}&Accesskey=${rootState.auth.accesskey}`)
				.then(resp => resp.data)
				.then(respdata => {				
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
		},
		GetUserStatus({commit, rootState}){
			return new Promise((resolve, reject) => {				
				axios.get(`/Userinfo/GetUserStatus?accesskey=${rootState.auth.accesskey}`)
				.then(resp => resp.data)
				.then(respdata => {
					const payload = respdata;					
					commit('userStatus_success',payload);					
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
		},	
	}
}

export default usersdata