import axios from 'axios';

const schentrancequestion = {
    
	actions: {
        getschentrance(context, userdata){
			return new Promise((resolve, reject) => {				
				axios({url: '/Schentrance/Getschentrance', data: userdata, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {	                   									
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
		},				
		getschentrancebyid({rootState},Id){
			return new Promise((resolve, reject) => {				
				axios({url: '/Schentrance/Getschentrancebyid', data: {id: Id, searchitem: null, username : rootState.auth.username, accesskey : rootState.auth.accesskey}, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {	                   
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
		},				
		processschentrance(context, userdata){
			return new Promise((resolve, reject) => {				
				axios({url: '/Schentrance/Processschentrance', data: userdata, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {	                   								
					resolve(respdata)
				})
				.catch(err => {
					reject(err)
				})
			})
		},
		getschentrancequestion({rootState},HeaderId){
			return new Promise((resolve, reject) => {				
				axios({url: '/Schentrance/Getschentrancequestion', data: {id: HeaderId, searchitem: null, username : rootState.auth.username, accesskey : rootState.auth.accesskey}, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {	                   									
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
		},				
		getschentrancequestionbyid({rootState},Id){
			return new Promise((resolve, reject) => {				
				axios({url: '/Schentrance/Getschentrancequestionbyid', data: {id: Id, searchitem: null, username : rootState.auth.username, accesskey : rootState.auth.accesskey}, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {	                   
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
		},				
		processschentrancequestion(context, userdata){
			return new Promise((resolve, reject) => {				
				axios({url: '/Schentrance/Processschentrancequestion', data: userdata, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {	                   								
					resolve(respdata)
				})
				.catch(err => {
					reject(err)
				})
			})
		},     	
	}
}

export default schentrancequestion