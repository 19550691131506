<template>
  <footer class="footer px-4">
    <hr/>
    <div class="align-items-center justify-content-lg-between">
      <div class="copyright text-center text-muted">
         
        </div>
    </div>
  </footer>
</template>
<script>
export default {
  data() {
    return {
      year: new Date().getFullYear()
    };
  }
};
</script>
<style></style>
