import axios from 'axios';

const schooldata = {
    state: { 		
		schoolinfo : {}
	},	
	mutations: {		
		schoolinfo_success(state, payload){			
			state.schoolinfo = payload	
		}
	},
	actions: {        
		getschooldetails({commit, rootState}){
			return new Promise((resolve, reject) => {				
				axios({url: '/Schoolinfo/Getschooldetails', data: {id: 1, searchitem: null, username : rootState.auth.username, accesskey : rootState.auth.accesskey}, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {
					const payload = respdata;					
					commit('schoolinfo_success',payload);					
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
		},				
		schoolupdate({commit}, userdata){
			return new Promise((resolve, reject) => {				
				axios({url: '/Schoolinfo/UpdateSchool', data: userdata, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {
					const payload = respdata;					
					commit('schoolinfo_success',payload);					
					resolve(respdata)
				})
				.catch(err => {
					reject(err)
				})
			})
		}
	}
}

export default schooldata