const adminRoutes = [
    {
        path: '/dashboard',
        name: 'Dashboard',
        component: () => import('@/views/Dashboard.vue'),
        meta: { user: ['Administrator', 'SchoolStaff', 'Student'] }
    },
    {
        path: '/user-profile',
        name: 'User Profile',
        component: () => import('@/views/UserProfile.vue'),
        meta: { user: ['Administrator', 'SchoolStaff', 'Student'] }
    },
    {
        path: '/admin/staff-registration',
        name: 'Staff Registration',
        component: () => import('@/views/PortalAdmin/Staff.vue'),
        meta: { user: 'Administrator' }
    },
    {
        path: '/admin/news-setup',
        name: 'Eduschool News',
        component: () => import('@/views/PortalAdmin/NewsSetup.vue'),
        meta: { user: 'Administrator' }
    },
    {
        path: '/admin/school-registrations',
        name: 'School Registrations',
        component: () => import('@/views/PortalAdmin/School.vue'),
        meta: { user: 'Administrator' }
    },
    {
        path: '/admin/school-staff-registrations',
        name: 'School Staff Registrations',
        component: () => import('@/views/PortalAdmin/Schoolstaff.vue'),
        meta: { user: 'Administrator' }
    },
    {
        path: '/admin/school-subject-registrations',
        name: 'School Subjects Registrations',
        component: () => import('@/views/PortalAdmin/Subjectsetup.vue'),
        meta: { user: 'Administrator' }
    },
    {
        path: '/admin/eduschool-cbt-exam',
        name: 'EduSchool CBT Exam',
        component: () =>
            import('@/views/PortalAdmin/Cbtexam.vue'),
        meta: { user: 'Administrator' }
    },
    {
        path: '/admin/eduschool-cbt-subject',
        name: 'EduSchool CBT Subject',
        component: () =>
            import('@/views/PortalAdmin/Cbtexamsubject.vue'),
        meta: { user: 'Administrator' }
    },
    {
        path: '/admin/eduschool-cbt-question',
        name: 'EduSchool CBT Questions',
        component: () =>
            import('@/views/PortalAdmin/Cbtquestion.vue'),
        meta: { user: 'Administrator' }
    },
    {
        path: '/admin/privacy-setup',
        name: 'Privacy Setup',
        component: () =>
            import('@/views/PortalAdmin/Privacy.vue'),
        meta: { user: 'Administrator' }
    },
    {
        path: '/admin/sms-subscriptions-setup',
        name: 'SMS Subscriptions Setup',
        component: () =>
            import('@/views/PortalAdmin/SmsSub.vue'),
        meta: { user: 'Administrator' }
    },
    {
        path: '/admin/community-setup',
        name: 'Community',
        component: () =>
            import('@/views/PortalAdmin/Community.vue'),
        meta: { user: 'Administrator' }
    },
]

export default adminRoutes
