import axios from 'axios';

const sub = {
   
	actions: {        			
		processcbtsubscription(context, userdata){
			return new Promise((resolve, reject) => {				
				axios({url: '/Subscription/ProcessCbtSubscription', data: userdata, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {	                   
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
		},				
		getcbtsubscription({rootState}){
			return new Promise((resolve, reject) => {				
				axios.get(`/News/GetCbtSubscription?Username=${rootState.auth.username}&accessKey=${rootState.auth.accesskey}`)
				.then(resp => resp.data)
				.then(respdata => {					
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
        },		
		getcbtsubscriptionhistory({rootState}){
			return new Promise((resolve, reject) => {				
				axios.get(`/News/GetCbtSubscriptionHistory?Username=${rootState.auth.username}&accessKey=${rootState.auth.accesskey}`)
				.then(resp => resp.data)
				.then(respdata => {					
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
        }		
	}
}

export default sub