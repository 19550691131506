import axios from 'axios';

const markrecord = {
    state: {  
		markrecords : []
	},	
	mutations: {		
		markrecords_success(state, payload){			
			state.markrecords = payload	
		}	
	},
	actions: {
        getmarkrecord(context, userdata){
			return new Promise((resolve, reject) => {				
				axios({url: '/Markrecord/Getmarkrecord', data: userdata, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {						
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
		},			
		getmarkrecordbyid(context, userdata){
			return new Promise((resolve, reject) => {				
				axios({url: '/Markrecord/Getmarkrecordbyid', data: userdata, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {									
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
		},						
		markrecordregistration(context, userdata){
			return new Promise((resolve, reject) => {				
				axios({url: '/Markrecord/Registermarkrecord', data: userdata, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {									
					resolve(respdata)
				})
				.catch(err => {
					reject(err)
				})
			})
        },  
		GetMarkRecordByStudentId({rootState}, {StudentId,SubjectId,ArmId,TermId,SessionId}){
			return new Promise((resolve, reject) => {				
				axios.get(`/Markrecord/GetMarkRecordByStudentId?StudentId=${StudentId}&SubjectId=${SubjectId}&ArmId=${ArmId}&TermId=${TermId}&SessionId=${SessionId}&Username=${rootState.auth.username}&accesskey=${rootState.auth.accesskey}`)
				.then(resp => resp.data)
				.then(respdata => {									
					resolve(respdata)
				})
				.catch(err => {
					reject(err)
				})
			})
		},  
		GetMarkRecordBySubject({rootState}, {SubjectId,ArmId,TermId,SessionId}){
			return new Promise((resolve, reject) => {				
				axios.get(`/Markrecord/GetMarkRecordBySubject?SubjectId=${SubjectId}&ArmId=${ArmId}&TermId=${TermId}&SessionId=${SessionId}&Username=${rootState.auth.username}&accesskey=${rootState.auth.accesskey}`)
				.then(resp => resp.data)
				.then(respdata => {									
					resolve(respdata)
				})
				.catch(err => {
					reject(err)
				})
			})
		},  
		GetStudentwithMarkRecord({rootState}, {armId,sessionId,termId,subjectId}){
			return new Promise((resolve, reject) => {				
				axios.get(`/Markrecord/GetStudentwithMarkRecord?armId=${armId}&sessionId=${sessionId}&termId=${termId}&subjectId=${subjectId}&Username=${rootState.auth.username}&accesskey=${rootState.auth.accesskey}`)
				.then(resp => resp.data)
				.then(respdata => {									
					resolve(respdata)
				})
				.catch(err => {
					reject(err)
				})
			})
		}      
	}
}

export default markrecord