import axios from 'axios';

const student = {
    state: {  
		students : [],
		studentinfo:{}
	},	
	mutations: {		
		students_success(state, payload){			
			state.students = payload	
		},
		studentinfo_success(state, payload){			
			state.studentinfo = payload	
		}	
	},
	actions: {
		studentregistration({commit}, userdata){
			return new Promise((resolve, reject) => {				
				axios({url: '/Student/ProcessStudent', data: userdata, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {
					const payload = respdata;					
					commit('students_success',payload);					
					resolve(respdata)
				})
				.catch(err => {
					reject(err)
				})
			})
        },
		deletestudent({commit, rootState},Id){
			return new Promise((resolve, reject) => {				
				axios.get(`/Student/Deletestudent?Id=${Id}&Username=${rootState.auth.username}&Accesskey=${rootState.auth.accesskey}`)
				.then(resp => resp.data)
				.then(respdata => {	
					commit('students_success',respdata);									
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
		},	
		getadmno({rootState}){
			return new Promise((resolve, reject) => {				
				axios.get(`/Student/Getadmissionno?Username=${rootState.auth.username}&Accesskey=${rootState.auth.accesskey}`)
				.then(resp => resp.data)
				.then(respdata => {									
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
		},
        getstudents({commit, rootState}){
			return new Promise((resolve, reject) => {				
				axios.get(`/Student/Getstudents?Username=${rootState.auth.username}&Accesskey=${rootState.auth.accesskey}`)
				.then(resp => resp.data)
				.then(respdata => {
					const payload = respdata;					
					commit('students_success',payload);					
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
		},
		getstudentsdeleted({commit, rootState}){
			return new Promise((resolve, reject) => {				
				axios.get(`/Student/GetStudentsDeleted?Username=${rootState.auth.username}&Accesskey=${rootState.auth.accesskey}`)
				.then(resp => resp.data)
				.then(respdata => {
					const payload = respdata;					
					commit('students_success',payload);					
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
		},
		getstudentsforguardian({rootState}){
			return new Promise((resolve, reject) => {				
				axios.get(`/Student/Getstudentsforguardian?Username=${rootState.auth.username}&Accesskey=${rootState.auth.accesskey}`)
				.then(resp => resp.data)
				.then(respdata => {										
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
		},
		getstudentsforguardiansingle({rootState}){
			return new Promise((resolve, reject) => {				
				axios.get(`/Student/Getstudentsforguardiansingle?Username=${rootState.auth.username}&Accesskey=${rootState.auth.accesskey}`)
				.then(resp => resp.data)
				.then(respdata => {										
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
		},
		getstudentbyuserid({commit, rootState}){
			return new Promise((resolve, reject) => {				
				axios.get(`/Student/Getstudentbyuserid?Username=${rootState.auth.username}&Accesskey=${rootState.auth.accesskey}`)
				.then(resp => resp.data)
				.then(respdata => {							
					commit('studentinfo_success',respdata);									
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
		},		
		getstudentbyclass({rootState}, {ClassId}){
			return new Promise((resolve, reject) => {				
				axios.get(`/Student/Getstudentbyclass?ClassId=${ClassId}&Username=${rootState.auth.username}&Accesskey=${rootState.auth.accesskey}`)
				.then(resp => resp.data)
				.then(respdata => {									
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
        },			
		getstudentbyoption({rootState}, {OptionId, ClassId}){
			return new Promise((resolve, reject) => {				
				axios.get(`/Student/GetByOptionIdStudent?OptionId=${OptionId}&ClassId=${ClassId}&Username=${rootState.auth.username}&Accesskey=${rootState.auth.accesskey}`)
				.then(resp => resp.data)
				.then(respdata => {
									
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
        },	
        getstudentbyarm({rootState}, {ArmId, ClassId}){
			return new Promise((resolve, reject) => {				
				axios.get(`/Student/GetByArmIdStudent?ArmId=${ArmId}&ClassId=${ClassId}&Username=${rootState.auth.username}&Accesskey=${rootState.auth.accesskey}`)
				.then(resp => resp.data)
				.then(respdata => {
									
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
		},		
		getstudentbyid({rootState},Id){
			return new Promise((resolve, reject) => {				
				axios.get(`/Student/Getstudentbyid?Id=${Id}&Username=${rootState.auth.username}&Accesskey=${rootState.auth.accesskey}`)
				.then(resp => resp.data)
				.then(respdata => {									
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
		},	
		getstudentformock({rootState},Id){
			return new Promise((resolve, reject) => {				
				axios.get(`/Student/Getstudentformock?Id=${Id}&Username=${rootState.auth.username}&Accesskey=${rootState.auth.accesskey}`)
				.then(resp => resp.data)
				.then(respdata => {														
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
		},			
		getByClassIdStudent({rootState},ClassId){
			return new Promise((resolve, reject) => {				
				axios.get(`/Student/GetByClassIdStudent?ClassId=${ClassId}&Username=${rootState.auth.username}&Accesskey=${rootState.auth.accesskey}`)
				.then(resp => resp.data)
				.then(respdata => {									
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
        },
		GetStudentsNotInHostel({rootState}){
			return new Promise((resolve, reject) => {				
				axios.get(`/Student/GetStudentsNotInHostel?Username=${rootState.auth.username}&Accesskey=${rootState.auth.accesskey}`)
				.then(resp => resp.data)
				.then(respdata => {									
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
        },
		GetStudentForReportCard({rootState},{SessionId,TermId,ClassId,SectionId}){
			return new Promise((resolve, reject) => {				
				axios.get(`/Student/GetStudentForReportCard?SessionId=${SessionId}&TermId=${TermId}&ClassId=${ClassId}&SectionId=${SectionId}&Username=${rootState.auth.username}&Accesskey=${rootState.auth.accesskey}`)
				.then(resp => resp.data)
				.then(respdata => {									
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
        },
		GetStudentForReportCardMid({rootState},{SessionId,TermId,ClassId,SectionId}){
			return new Promise((resolve, reject) => {				
				axios.get(`/Student/GetStudentForReportCardMid?SessionId=${SessionId}&TermId=${TermId}&ClassId=${ClassId}&SectionId=${SectionId}&Username=${rootState.auth.username}&Accesskey=${rootState.auth.accesskey}`)
				.then(resp => resp.data)
				.then(respdata => {									
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
        },
	}
}

export default student