const homeRoutes = [
    {
        path: '/login',
        name: 'Login',
        component: () => import('@/views/Account/Login.vue')     
    },
    {
        path: '/retrieve-password',
        name: 'Retrieve Password',
        component: () => import('@/views/Account/Retrievepassword.vue')       
    },
    {
        path: '/change-password',
        name: 'Change Password',
        component: () => import('@/views/Account/Changepassword.vue')       
    },
    {
        path: '/privacy',
        name: 'Privacy',
        component: () => import('@/views/Privacy.vue')       
    },
    {
        path: '/entrance-exam',
        name: 'Entrance Exam',
        component: () => import('@/views/PortalStudent/CBT/Component/Entranceexam.vue')       
    }   
]

export default homeRoutes
