import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

import auth from "@/store/modules/Auth/auth";
import term from "@/store/modules/settings/term.store";
import schclass from "@/store/modules/settings/schclass.store";
import schoption from "@/store/modules/settings/option.store";
import scharm from "@/store/modules/settings/arm.store";
import schsubject from "@/store/modules/settings/schsubject.store";
import schsubjecttopic from "@/store/modules/settings/subjecttopic.store";
import mock from "@/store/modules/settings/mock.store";
import mocksubject from "@/store/modules/settings/mocksubject.store";
import skill from "@/store/modules/settings/skill.store";
import conduct from "@/store/modules/settings/conduct.store";
import grade from "@/store/modules/settings/grade.store";
import appdatas from "@/store/modules/settings/appdatas.store";
import usersdata from "@/store/modules/settings/usersdata.store";
import addressinfo from "@/store/modules/settings/addressinfo.store";
import schooldata from "@/store/modules/settings/schooldata.store";
import student from "@/store/modules/student/student.store";
import uploader from "@/store/modules/settings/uploader.store";
import classattendance from "@/store/modules/student/classattendance.store";
import classattendancemid from "@/store/modules/student/classattendancemid_store";
import markrecord from "@/store/modules/student/markrecord.store";
import markrecordmid from "@/store/modules/student/markrecordmid.store";
import studentconduct from "@/store/modules/student/studentconduct.store";
import studentconductmid from "@/store/modules/student/studentconductmid.store";
import studentskill from "@/store/modules/student/studentskill.store";
import studentskillmid from "@/store/modules/student/studentskillmid.store";
import physicaldev from "@/store/modules/student/physicaldev.store";
import physicaldevmid from "@/store/modules/student/physicaldevmid.store";
import studentarchievement from "@/store/modules/student/studentarchievement.store";
import promotion from "@/store/modules/student/promotion.store";
import alumni from "@/store/modules/student/alumni.store";
import noteonstudent from "@/store/modules/student/noteonstudent.store";
import mockrecord from "@/store/modules/student/mockrecord.store";
import homework from "@/store/modules/student/homework.store";
import staff from "@/store/modules/staff/staff.store";
import classteacher from "@/store/modules/staff/classteacher.store";
import subjectteacher from "@/store/modules/staff/subjectteacher.store";
import schquestion from "@/store/modules/schcbt/schquestion.store";
import smsSender from "@/store/modules/sms/smsSender.store";
import reportcard from "@/store/modules/report/reportcard/reportcard.store";
import reportcardmid from "@/store/modules/report/reportcard/reportcardmid.store";
import mockreport from "@/store/modules/report/reportcard/mockreport.store";
import examquestion from "@/store/modules/schcbt/examquestion.store";
import schentrancequestion from "@/store/modules/schcbt/schentrancequestion.store";
import Portalschool from "@/store/modules/admin/Portalschool.store";
import companystaff from "@/store/modules/admin/companystaff.store";
import subjectsetup from "@/store/modules/admin/subjectsetup.store";
import cbtexam from "@/store/modules/admin/cbtexam.store";
import cbtexamsubject from "@/store/modules/admin/cbtexamsubject.store";
import cbtquestion from "@/store/modules/admin/cbtquestion.store";
import news from "@/store/modules/admin/news.store";
import schsite from "@/store/modules/website/schsite.store";
import entranceexam from "@/store/modules/schcbt/entranceexam.store";
import cbt from "@/store/modules/cbt/cbt.store";
import tcquestion from "@/store/modules/schcbt/tcquestion.store";
import tcexamquestion from "@/store/modules/schcbt/tcexamquestion.store";
import sub from "@/store/modules/subscription/sub.store";
import smsSub from "@/store/modules/subscription/smssub.store";
import reportcardsub from "@/store/modules/subscription/reportcardsub.store";
import schoolbill from "@/store/modules/finance/schoolbill.store";
import otherincome from "@/store/modules/finance/otherincome.store";
import outcomepayment from "@/store/modules/finance/outcomepayment.store";
import libraryCategory from "@/store/modules/library/libraryCategory.store";
import libraryBook from "@/store/modules/library/libraryBook.store";
import libraryBookStock from "@/store/modules/library/libraryBookStock.store";
import libraryBookIssue from "@/store/modules/library/libraryBookIssue.store";
import hostel from "@/store/modules/hostel/hostel.store";
import hostelFloor from "@/store/modules/hostel/hostelFloor.store"
import hostelRoom from "@/store/modules/hostel/hostelRoom.store"
import hostelBed from "@/store/modules/hostel/hostelBed.store"
import hostelStudentBed from "@/store/modules/hostel/hostelStudentBed.store"
import LessonNote from "@/store/modules/Academic/LessonNote"
import LessonPlan from "@/store/modules/Academic/LessonPlan"
import ClassNote from "@/store/modules/Academic/ClassNote"
import SchoolStaff from "@/store/modules/SchoolPortal/SchoolStaff"
import NewsSetup from "@/store/modules/admin/NewsSetup"
import School from "@/store/modules/admin/School"
import SchoolType from "@/store/modules/admin/SchoolType"
import Community from "@/store/modules/admin/Community.store"


Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [createPersistedState()],
  modules: { 
    auth,
    term,
    schclass,
    schoption,
    scharm,
    schsubject,
    mock,
    mocksubject,
    skill,
    conduct,
    grade,
    appdatas,
    usersdata,
    addressinfo,
    schooldata,
    student,
    uploader,
    classattendance,
    classattendancemid,
    markrecord,
    markrecordmid,
    studentconduct,
    studentskill,
    studentskillmid,
    studentconductmid,
    physicaldev,
    physicaldevmid,
    studentarchievement,
    promotion,
    alumni,
    noteonstudent,
    mockrecord,
    homework,
    staff,
    classteacher,
    subjectteacher,
    schquestion,
    smsSender,
    reportcard,
    reportcardmid,
    schsubjecttopic,
    mockreport,
    examquestion,
    sub,
    smsSub,
    reportcardsub,
    Portalschool,
    companystaff,
    subjectsetup,
    cbtexam,
    cbtexamsubject,
    cbtquestion,
    schentrancequestion,
    news,
    schsite,
    entranceexam,
    cbt,
    tcquestion,
    tcexamquestion,
    schoolbill,
    otherincome,
    outcomepayment,
    libraryCategory,
    libraryBook,
    libraryBookStock,
    libraryBookIssue,
    hostel,
    hostelFloor,
    hostelRoom,
    hostelBed,
    hostelStudentBed,
    LessonNote,
    LessonPlan,
    ClassNote,
    SchoolStaff,
    NewsSetup,
    School,
    SchoolType,
    Community
  }
});
