import axios from 'axios';

const classattendancemid = {
    state: {  
		classattendancemids : []
	},	
	mutations: {		
		classattendancemids_success(state, payload){			
			state.classattendancemids = payload	
		}	
	},
	actions: {
        getclassattendancemid(context, userdata){
			return new Promise((resolve, reject) => {				
				axios({url: '/Classattendancemid/Getclassattendancemid', data: userdata, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {						
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
		},			
		getclassattendancemidbyid(context, userdata){
			return new Promise((resolve, reject) => {				
				axios({url: '/Classattendancemid/Getclassattendancemidbyid', data: userdata, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {									
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
		},						
		classattendancemidregistration(context, userdata){
			return new Promise((resolve, reject) => {				
				axios({url: '/Classattendancemid/Registerclassattendancemid', data: userdata, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {									
					resolve(respdata)
				})
				.catch(err => {
					reject(err)
				})
			})
        },        
	}
}

export default classattendancemid