import axios from 'axios';

const schclass = {
    state: {  
		classcategory : [],
		schclass : []
	},	
	mutations: {		
		class_success(state, payload){			
			state.schclass = payload	
		},
		classcategory_success(state, payload){			
			state.classcategory = payload	
		}		
	},
	actions: {
        getclasscategory({commit, rootState}){
			return new Promise((resolve, reject) => {				
				axios({url: '/Schclass/Getclasscategory', data: {id: '1', searchitem: null, username : rootState.auth.username, accesskey : rootState.auth.accesskey}, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {
					const payload = respdata;					
					commit('classcategory_success',payload);					
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
		},
		
		getclassinclassassigned({rootState}){
			return new Promise((resolve, reject) => {				
				axios({url: '/Schclass/Getclassinclassassigned', data: {id: '1', searchitem: null, username : rootState.auth.username, accesskey : rootState.auth.accesskey}, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {									
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
		},
		getclassinsubjectassigned({rootState}){
			return new Promise((resolve, reject) => {				
				axios({url: '/Schclass/Getclassinsubjectassigned', data: {id: '1', searchitem: null, username : rootState.auth.username, accesskey : rootState.auth.accesskey}, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {							
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
		},	
		getclass({commit, rootState}){
			return new Promise((resolve, reject) => {				
				axios({url: '/Schclass/Getclass', data: {id: '1', searchitem: null, username : rootState.auth.username, accesskey : rootState.auth.accesskey}, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {
					const payload = respdata;					
					commit('classcategory_success',payload);					
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
		},
		GetAllClass({rootState},{SearchValue,PageNumber,PageSize}){
			return new Promise((resolve, reject) => {				
				const headers = {
                    Authorization: `bearer ${rootState.auth.token}`
                };				
				axios.get(`${process.env.VUE_APP_ENV_API_URL}PortalClass/GetAllClass?SearchValue=${SearchValue}&PageNumber=${PageNumber}&PageSize=${PageSize}`,{ headers })
				.then(resp => resp.data)
				.then(respdata => {									
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
		},			
		GetByIdClass({rootState},Id){
			return new Promise((resolve, reject) => {				
				const headers = {
                    Authorization: `bearer ${rootState.auth.token}`
                };				
				axios.get(`${process.env.VUE_APP_ENV_API_URL}PortalClass/GetByIdClass?Id=${Id}`,{ headers })
				.then(resp => resp.data)
				.then(respdata => {									
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
		},		
		CreateClass({commit,rootState}, userdata){
			return new Promise((resolve, reject) => {	
				const headers = {
                    Authorization: `bearer ${rootState.auth.token}`
                };				
				axios.post(`${process.env.VUE_APP_ENV_API_URL}PortalClass/CreateClass`, userdata,{ headers })
				.then(resp => resp.data)
				.then(respdata => {
					const responseData = respdata;					
					commit('class_success',responseData);					
					resolve(respdata)
				})
				.catch(err => {
					reject(err)
				})
			})
		},
		UpdateClass({commit,rootState}, userdata){
			return new Promise((resolve, reject) => {	
				const headers = {
                    Authorization: `bearer ${rootState.auth.token}`
                };				
				axios.post(`${process.env.VUE_APP_ENV_API_URL}PortalClass/UpdateClass`, userdata,{ headers })
				.then(resp => resp.data)
				.then(respdata => {
					const responseData = respdata;					
					commit('class_success',responseData);					
					resolve(respdata)
				})
				.catch(err => {
					reject(err)
				})
			})
		},
		getclassforstudentreport(context, userdata){
			return new Promise((resolve, reject) => {				
				axios({url: '/Schclass/Getclassforstudentreport', data: userdata, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {									
					resolve(respdata)
				})
				.catch(err => {
					reject(err)
				})
			})
		},
		getclassforstudentreportmid(context, userdata){
			return new Promise((resolve, reject) => {				
				axios({url: '/Schclass/Getclassforstudentreportmid', data: userdata, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {									
					resolve(respdata)
				})
				.catch(err => {
					reject(err)
				})
			})
		},
		GetClassInSchoolBill({rootState},{SessionId,TermId}){
			return new Promise((resolve, reject) => {				
				axios.get(`/Schclass/GetClassInSchoolBill?SessionId=${SessionId}&TermId=${TermId}&Username=${rootState.auth.username}&Accesskey=${rootState.auth.accesskey}`)
				.then(resp => resp.data)
				.then(respdata => {									
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
        },
	}
}

export default schclass