var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.accessDetails && _vm.staffDetails)?_c('side-bar',{attrs:{"background-color":_vm.sidebarBackground,"short-title":"SchoolDock","title":"SchoolDock"}},[(_vm.staffDetails)?_c('template',{slot:"links"},[_c('sidebar-item',{attrs:{"link":{
    name: 'Dashboard',
    icon: 'fa fa-tv text-primary',
    path: '/dashboard',
  }}}),(_vm.staffDetails.isRegistrar == true)?_c('sidebar-item',{attrs:{"link":{
    name: 'Registrar',
    icon: 'fa fa-id-card text-blue',
    path: '/school/registrar',
  }}}):_vm._e(),_c('sidebar-item',{attrs:{"link":{
    name: 'Academic',
    icon: 'fa fa-chalkboard-teacher text-blue',
    path: '/school/academic',
  }}}),_c('sidebar-item',{attrs:{"link":{
    name: 'Student Record',
    icon: 'fa fa-clipboard text-blue',
    path: '/school/student-record',
  }}}),(_vm.staffDetails.isHostelMaster == true)?_c('sidebar-item',{attrs:{"link":{
    name: 'Hostel',
    icon: 'fa fa-bed text-blue',
    path: '/school/hostel',
  }}}):_vm._e(),(_vm.staffDetails.isLibraryAdmin == true)?_c('sidebar-item',{attrs:{"link":{
    name: 'Library',
    icon: 'fa fa-book-reader text-blue',
    path: '/school/library',
  }}}):_vm._e(),(_vm.staffDetails.isBursal == true)?_c('sidebar-item',{attrs:{"link":{
    name: 'Finance',
    icon: 'fa fa-cash-register text-blue',
    path: '/school/finance',
  }}}):_vm._e(),(_vm.staffDetails.isPortalAdmin == true)?_c('sidebar-item',{attrs:{"link":{
    name: 'Notification',
    icon: 'fa fa-bell text-blue',
    path: '/school/notifications',
  }}}):_vm._e(),(_vm.staffDetails.isPortalAdmin == true)?_c('sidebar-item',{attrs:{"link":{
    name: 'Chat',
    icon: 'fab fa-rocketchat text-blue',
    path: '/school/notifications',
  }}}):_vm._e(),(_vm.staffDetails.isPortalAdmin == true)?_c('sidebar-item',{attrs:{"link":{
    name: 'SMS',
    icon: 'fas fa-sms text-blue',
    path: '/school/sms-sender',
  }}}):_vm._e(),(_vm.staffDetails.isPortalAdmin == true)?_c('sidebar-item',{attrs:{"link":{
    name: 'Website',
    icon: 'fa fa-window-restore text-blue',
    path: '/school/website-setup',
  }}}):_vm._e(),_c('sidebar-item',{attrs:{"link":{
    name: 'Report',
    icon: 'fa fa-chart-bar text-blue',
    path: '/school/report',
  }}}),(_vm.staffDetails.isPortalAdmin == true)?_c('sidebar-item',{attrs:{"link":{
    name: 'Setting',
    icon: 'fa fa-sliders-h text-blue',
    path: '/school/environment-settings',
  }}}):_vm._e(),(_vm.staffDetails.isPortalAdmin == true)?_c('sidebar-item',{attrs:{"link":{
    name: 'Subscription',
    icon: 'fa fa-money-check text-blue',
    path: '/school/subscription',
  }}}):_vm._e(),_c('sidebar-item',{attrs:{"link":{
    name: 'Profile',
    icon: 'fa fa-user text-blue',
    path: '/user-profile',
  }}}),_c('sidebar-item',{attrs:{"link":{
    name: 'Help',
    icon: 'fa fa-question text-blue',
    path: '/dashboard',
  }}})],1):_vm._e()],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }