<template>
<div>
     <InternetAlert/>
<div class="main-content">
    
    <ul class="cb-slideshow">
        <li><span>Image 01</span>
            <div>
                <h3 class="text-hide">SchoolDock</h3>
            </div>
        </li>
        <li><span>Image 02</span>
            <div>
                <h3 class="text-hide">SchoolDock</h3>

            </div>
        </li>
        <li><span>Image 03</span>
            <div>
                <h3 class="text-hide">SchoolDock</h3>
            </div>
        </li>
        <li><span>Image 04</span>
            <div>
                <h3 class="text-hide">SchoolDock</h3>
            </div>
        </li>
        <li><span>Image 05</span>
            <div>
                <h3 class="text-hide">SchoolDock</h3>
            </div>
        </li>
        <li><span>Image 06</span>
            <div>
                <h3 class="text-hide">SchoolDock</h3>
            </div>
        </li>
    </ul>
    <!-- Header -->
    <div class="header py-8 py-lg-8">

    </div>
    <!-- Page content -->
    <div class="container mt--8 pb-5">       
        <slide-y-up-transition mode="out-in" origin="center top">
            <router-view></router-view>
        </slide-y-up-transition>
    </div>
    <footer class="py-5">
       
    </footer>
</div>
</div>
</template>

<script>
import {
    SlideYUpTransition
} from 'vue2-transitions'
import InternetAlert from "../views/InternetAlert.vue"
export default {
    name: 'auth-layout',
    components: {
        SlideYUpTransition,
        InternetAlert
    },
    data() {
        return {
            year: new Date().getFullYear(),
            showMenu: false
        }
    }
}
</script>

<style>
</style>
