import axios from 'axios';

const hostel = {
    namespaced: true,
    
	actions: {		
		ProcessHostel(context, userdata){
			return new Promise((resolve, reject) => {				
				axios({url: '/Hostel/ProcessHostel', data: userdata, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {									
					resolve(respdata)
				})
				.catch(err => {
					reject(err)
				})
			})
		}, 		
		GetHostel({rootState}){
			return new Promise((resolve, reject) => {				
				axios.get(`/Hostel/GetHostel?Username=${rootState.auth.username}&Accesskey=${rootState.auth.accesskey}`)
				.then(resp => resp.data)
				.then(respdata => {									
					resolve(respdata)
				})
				.catch(err => {
					reject(err)
				})
			})
		}, 
		GetByIdHostel({rootState}, Id){
			return new Promise((resolve, reject) => {				
				axios.get(`/Hostel/GetByIdHostel?Id=${Id}&Username=${rootState.auth.username}&Accesskey=${rootState.auth.accesskey}`)
				.then(resp => resp.data)
				.then(respdata => {									
					resolve(respdata)
				})
				.catch(err => {
					reject(err)
				})
			})
		}
	}
}

export default hostel