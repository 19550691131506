var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.accessDetails)?_c('div',{staticClass:"wrapper",class:{ 'nav-open': _vm.$sidebar.showSidebar }},[_c('InternetAlert'),(_vm.accessDetails.isSchoolStaff == true)?_c('StaffNavbar'):_vm._e(),(
      _vm.accessDetails.isSchoolStudent == true ||
        _vm.accessDetails.isStudentGuardian == true
    )?_c('StudentNavbar'):_vm._e(),(
      _vm.accessDetails.isSuperAdmin == true ||
        _vm.accessDetails.isAdmin == true ||
        _vm.accessDetails.isSupportAdmin == true ||
         _vm.accessDetails.isAffliateMarketer == true
    )?_c('AdminNavbar'):_vm._e(),_c('div',{staticClass:"main-content",attrs:{"data":_vm.sidebarBackground}},[_c('dashboard-navbar'),_c('div',{on:{"click":_vm.toggleSidebar}},[_c('fade-transition',{attrs:{"duration":200,"origin":"center top","mode":"out-in"}},[_c('router-view')],1),(!_vm.$route.meta.hideFooter)?_c('content-footer'):_vm._e()],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }