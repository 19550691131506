<template>
  <div id="app">
    <notifications></notifications>
    <router-view/>
    <div class="refresh-container" v-if="hashChanged && $root.env !== 'development'">
      <div class="notification-header">
        <button type="button" class="close-refresh-modal" @click="closeModal" aria-label="Close">
          <span aria-hidden="true"><i class="fal fa-times fa-sm"></i></span>
        </button>
      </div>
      <div class="notification-body">
        <div class="notification-button">
          <p class="text-center font12">An update is available. Please save all current work and click update below. You can also accept these updates by refreshing your browswer at any time.</p>
          <p class="text-center"><span class="font10">Not updating may result in errors.</span></p>
        </div>
        <div class="refresh-button text-center">
          <button class="btn btn-default" @click="reloadApp">Update</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// mixins
import { refreshPageMixin } from '@/mixins/pageReferesher.mixin';
export default {
    mixins: [refreshPageMixin],
}
</script>