import axios from 'axios';
const cbtexam = {    
	actions: {
        processcbtexam(context, UserData){
			return new Promise((resolve, reject) => {				
				axios({url: '/Cbtexam/Processcbtexam', data: UserData, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {									
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
        },
        getcbtexam({rootState}){
			return new Promise((resolve, reject) => {				
				axios({url: '/Cbtexam/Getcbtexam', data: {id: 1, searchitem: null, username : rootState.auth.username, accesskey : rootState.auth.accesskey}, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {									
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
		},			
		getcbtexamsearch({rootState}, item){
			return new Promise((resolve, reject) => {				
				axios({url: '/Cbtexam/Getcbtexamsearch', data: {id: 1, searchitem: item, username : rootState.auth.username, accesskey : rootState.auth.accesskey}, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {									
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
		},		
		getcbtexamid({rootState},Id){
			return new Promise((resolve, reject) => {				
				axios({url: '/Cbtexam/Getcbtexamid', data: {id: Id, searchitem: null, username : rootState.auth.username, accesskey : rootState.auth.accesskey}, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {									
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
		},
		getcbtexamyear({rootState},Id){
			return new Promise((resolve, reject) => {				
				axios({url: '/Cbtexam/Getcbtexamyear', data: {id: Id, searchitem: null, username : rootState.auth.username, accesskey : rootState.auth.accesskey}, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {									
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
        },
       
	}
}

export default cbtexam