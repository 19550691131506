import axios from 'axios';

const studentconductmid = {
    state: {  
		studentconductmids : []
	},	
	mutations: {		
		studentconductmids_success(state, payload){			
			state.studentconductmids = payload	
		}	
	},
	actions: {
        getstudentconductmid(context, userdata){
			return new Promise((resolve, reject) => {				
				axios({url: '/Studentconductmid/Getstudentconductmid', data: userdata, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {						
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
		},			
		getstudentconductmidbyid(context, userdata){
			return new Promise((resolve, reject) => {				
				axios({url: '/Studentconductmid/Getstudentconductmidbyid', data: userdata, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {									
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
		},						
		studentconductmidregistration(context, userdata){
			return new Promise((resolve, reject) => {				
				axios({url: '/Studentconductmid/Registerstudentconductmid', data: userdata, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {									
					resolve(respdata)
				})
				.catch(err => {
					reject(err)
				})
			})
        },        
	}
}

export default studentconductmid