import axios from 'axios';

const schquestion = {
    state: {  
		schquestions : []
	},	
	mutations: {		
		schquestions_success(state, payload){			
			state.schquestions = payload	
		}	
	},
	actions: {
        getschquestionheader(context, UserData){
			return new Promise((resolve, reject) => {				
				axios({url: '/Schquestion/Getschquestionheader', data: UserData, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {	                   									
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
		},				
		getschquestionheaderbyid({rootState},Id){
			return new Promise((resolve, reject) => {				
				axios({url: '/Schquestion/Getschquestionheaderbyid', data: {id: Id, searchitem: null, username : rootState.auth.username, accesskey : rootState.auth.accesskey}, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {	                   
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
		},				
		processschquestionheader(context, userdata){
			return new Promise((resolve, reject) => {				
				axios({url: '/Schquestion/Processschquestionheader', data: userdata, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {	                   								
					resolve(respdata)
				})
				.catch(err => {
					reject(err)
				})
			})
		},
		shareschquestion(context, userdata){
			return new Promise((resolve, reject) => {				
				axios({url: '/Schquestion/Shareschquestion', data: userdata, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {	                   								
					resolve(respdata)
				})
				.catch(err => {
					reject(err)
				})
			})
		},
		shareallschquestion(context, userdata){
			return new Promise((resolve, reject) => {				
				axios({url: '/Schquestion/Shareallschquestion', data: userdata, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {	                   								
					resolve(respdata)
				})
				.catch(err => {
					reject(err)
				})
			})
		},
		deleteschquestion({rootState},{QuestionId,HeaderId}){
			return new Promise((resolve, reject) => {				
				axios({url: '/Schquestion/Deleteschquestion', data: {id: QuestionId, idd: HeaderId, searchitem: null, username : rootState.auth.username, accesskey : rootState.auth.accesskey}, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {	                   									
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
		},		
		getschquestion({rootState},HeaderId){
			return new Promise((resolve, reject) => {				
				axios({url: '/Schquestion/Getschquestion', data: {id: HeaderId, searchitem: null, username : rootState.auth.username, accesskey : rootState.auth.accesskey}, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {	                   									
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
		},				
		getschquestionbyid({rootState},Id){
			return new Promise((resolve, reject) => {				
				axios({url: '/Schquestion/Getschquestionbyid', data: {id: Id, searchitem: null, username : rootState.auth.username, accesskey : rootState.auth.accesskey}, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {	                   
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
		},				
		processschquestion(context, userdata){
			return new Promise((resolve, reject) => {				
				axios({url: '/Schquestion/Processschquestion', data: userdata, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {	                   								
					resolve(respdata)
				})
				.catch(err => {
					reject(err)
				})
			})
		},
		getschanswer({rootState},QuestionId){
			return new Promise((resolve, reject) => {				
				axios({url: '/Schquestion/Getschanswer', data: {id: QuestionId, searchitem: null, username : rootState.auth.username, accesskey : rootState.auth.accesskey}, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {	                   									
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
		},
		processschanswer(context, userdata){
			return new Promise((resolve, reject) => {				
				axios({url: '/Schquestion/Processschanswer', data: userdata, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {	                   								
					resolve(respdata)
				})
				.catch(err => {
					reject(err)
				})
			})
        }, 		      	
	}
}

export default schquestion