import axios from 'axios';

const smsSub = {
	namespaced: true,
	actions: {				
		getsms({rootState}){
			return new Promise((resolve, reject) => {				
				axios.get(`/Sms/GetSms?Username=${rootState.auth.username}&accessKey=${rootState.auth.accesskey}`)
				.then(resp => resp.data)
				.then(respdata => {					
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
        },		
		GetSmsType({rootState}){
			return new Promise((resolve, reject) => {				
				axios.get(`/Sms/GetSmsType?accessKey=${rootState.auth.accesskey}`)
				.then(resp => resp.data)
				.then(respdata => {					
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
        },
		SearchSmsSuscription({rootState},SmsUnit){
			return new Promise((resolve, reject) => {				
				axios.get(`/Sms/SearchSmsSuscription?SmsUnit=${SmsUnit}&Username=${rootState.auth.username}&accessKey=${rootState.auth.accesskey}`)
				.then(resp => resp.data)
				.then(respdata => {					
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
        },
		ConfirmPayment({rootState},{PaymentReference,SmsUnit}){
			return new Promise((resolve, reject) => {				
				axios.get(`/Sms/ConfirmPayment?PaymentReference=${PaymentReference}&SmsUnit=${SmsUnit}&Username=${rootState.auth.username}&accessKey=${rootState.auth.accesskey}`)
				.then(resp => resp.data)
				.then(respdata => {					
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
        },					
	}
}

export default smsSub