import store from '@/store/index'

export default () => {  
  const authenticateUser = async (to, from, next) => {
    const token = store.getters['auth/_userAuthToken']
    if (to.meta.user) {
      if (token !== '') {
        return next()
      } else {      
        return next('/login')
      }     
    } else {     
      return next()
    }
  }

  return {
    authenticateUser
  }
}
