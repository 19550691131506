import axios from "axios";

const LessonPlan = {
 // namespaced: true,
  actions: {
    ProcessLessonPlan(context, userdata) {
      return new Promise((resolve, reject) => {      
        axios
          .post(
            `/LessonPlan/ProcessLessonPlan`,
            userdata
          )
          .then((resp) => resp.data)
          .then((respdata) => {
            resolve(respdata);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    ApproveLessonPlan({rootState}, LessonPlanId) {
      return new Promise((resolve, reject) => {       
        axios
          .post(
            `/LessonPlan/ApproveLessonPlan?LessonPlanId=${LessonPlanId}&username=${rootState.auth.username}&accesskey=${rootState.auth.accesskey}`
          )
          .then((resp) => resp.data)
          .then((respdata) => {
            resolve(respdata);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    DisapproveLessonPlan({ rootState }, {LessonPlanId,Comment}) {
      return new Promise((resolve, reject) => {       
        axios
          .get(
            `/LessonPlan/DisapproveLessonPlan?LessonPlanId=${LessonPlanId}&Comment=${Comment}&username=${rootState.auth.username}&accesskey=${rootState.auth.accesskey}`
          )
          .then((resp) => resp.data)
          .then((respdata) => {
            resolve(respdata);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    GetLessonPlans({ rootState },{SessionId,TermId}) {
      return new Promise((resolve, reject) => {       
        axios
          .get(
            `/LessonPlan/GetLessonPlans?SessionId=${SessionId}&TermId=${TermId}&username=${rootState.auth.username}&accesskey=${rootState.auth.accesskey}`
          )
          .then((resp) => resp.data)
          .then((respdata) => {
            resolve(respdata);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    GetLessonPlansPending({ rootState },{SessionId,TermId}) {
      return new Promise((resolve, reject) => {       
        axios
          .get(
            `/LessonPlan/GetLessonPlansPending?SessionId=${SessionId}&TermId=${TermId}&username=${rootState.auth.username}&accesskey=${rootState.auth.accesskey}`
          )
          .then((resp) => resp.data)
          .then((respdata) => {
            resolve(respdata);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    GetLessonPlansApproved({ rootState },{SessionId,TermId}) {
      return new Promise((resolve, reject) => {       
        axios
          .get(
            `/LessonPlan/GetLessonPlansApproved?SessionId=${SessionId}&TermId=${TermId}&username=${rootState.auth.username}&accesskey=${rootState.auth.accesskey}`
          )
          .then((resp) => resp.data)
          .then((respdata) => {
            resolve(respdata);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    GetLessonPlansDisApproved({ rootState },{SessionId,TermId}) {
      return new Promise((resolve, reject) => {       
        axios
          .get(
            `/LessonPlan/GetLessonPlansDisApproved?SessionId=${SessionId}&TermId=${TermId}&username=${rootState.auth.username}&accesskey=${rootState.auth.accesskey}`
          )
          .then((resp) => resp.data)
          .then((respdata) => {
            resolve(respdata);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    GetByIdLessonPlan({ rootState },Id) {
      return new Promise((resolve, reject) => {       
        axios
          .get(
            `/LessonPlan/GetByIdLessonPlan?Id=${Id}&username=${rootState.auth.username}&accesskey=${rootState.auth.accesskey}`
          )
          .then((resp) => resp.data)
          .then((respdata) => {
            resolve(respdata);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },   
    GetBySubjectLessonPlan({ rootState },{SubjectId,SessionId,TermId}) {
      return new Promise((resolve, reject) => {       
        axios
          .get(
            `/LessonPlan/GetBySubjectLessonPlan?SubjectId=${SubjectId}&SessionId=${SessionId}&TermId=${TermId}&username=${rootState.auth.username}&accesskey=${rootState.auth.accesskey}`
          )
          .then((resp) => resp.data)
          .then((respdata) => {
            resolve(respdata);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },   
  },
};

export default LessonPlan;
