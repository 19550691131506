import axios from 'axios';

const NewsSetup = {
    namespaced: true,    
    actions: {
        GetNewsCategoriesForPortal({ rootState }) {
            return new Promise((resolve, reject) => {  
                const headers = {
                    Authorization: `bearer ${rootState.auth.token}`
                };                    
                axios.get(`${process.env.VUE_APP_ENV_API_URL}News/GetNewsCategoriesForPortal`, { headers })
                    .then(resp => resp.data)
                    .then(data => {                       
                        resolve(data)
                    })
                    .catch(err => {                       
                        reject(err)
                    })
            })
        }, 
        GetNewsForPortal({ rootState },{SearchValue,PageNumber,PageSize,NewsCategoryId}) {
            return new Promise((resolve, reject) => {  
                const headers = {
                    Authorization: `bearer ${rootState.auth.token}`
                };                    
                axios.get(`${process.env.VUE_APP_ENV_API_URL}News/GetNewsForPortal?SearchValue=${SearchValue}&PageNumber=${PageNumber}&PageSize=${PageSize}&NewsCategoryId=${NewsCategoryId}`, { headers })
                    .then(resp => resp.data)
                    .then(data => {                       
                        resolve(data)
                    })
                    .catch(err => {                       
                        reject(err)
                    })
            })
        }, 
        GetNewsByIdForPortal({ rootState },NewsId) {
            return new Promise((resolve, reject) => {  
                const headers = {
                    Authorization: `bearer ${rootState.auth.token}`
                };                    
                axios.get(`${process.env.VUE_APP_ENV_API_URL}News/GetNewsByIdForPortal?NewsId=${NewsId}`, { headers })
                    .then(resp => resp.data)
                    .then(data => {                       
                        resolve(data)
                    })
                    .catch(err => {                       
                        reject(err)
                    })
            })
        }, 
        CreateNews({ rootState }, payload) {
            return new Promise((resolve, reject) => {  
                const headers = {
                    Authorization: `bearer ${rootState.auth.token}`
                };                      
                axios.post(`${process.env.VUE_APP_ENV_API_URL}News/CreateNews`, payload, { headers })
                    .then(resp => resp.data)
                    .then(data => {                       
                        resolve(data)
                    })
                    .catch(err => {                       
                        reject(err)
                    })
            })
        },    
        UpdateNews({ rootState }, payload) {
            return new Promise((resolve, reject) => {  
                const headers = {
                    Authorization: `bearer ${rootState.auth.token}`
                };                      
                axios.post(`${process.env.VUE_APP_ENV_API_URL}News/UpdateNews`, payload, { headers })
                    .then(resp => resp.data)
                    .then(data => {                       
                        resolve(data)
                    })
                    .catch(err => {                       
                        reject(err)
                    })
            })
        },     
    },
}

export default NewsSetup