import Vue from "vue";
import Router from "vue-router";
import RouteGuard from '@/mixins/routeGuard'
import routes from '@/routers/routes'
Vue.use(Router);

const { authenticateUser } = RouteGuard()
const router =  new Router({
  linkExactActiveClass: "active",
  mode: "history",
  routes: routes,
});

// global route guard
router.beforeEach((to, from, next) => authenticateUser(to, from, next))

export default router
