import "@/assets/vendor/nucleo/css/nucleo.css";
import "@/assets/slider.css";
import "@/assets/spinner.css";
import "@/assets/scss/sdock.scss";
import globalComponents from "./globalComponents";
import globalDirectives from "./globalDirectives";
import SidebarPlugin from "@/components/SidebarPlugin/index"
import NotificationPlugin from "@/components/NotificationPlugin/index"
import CKEditor from '@ckeditor/ckeditor5-vue2';
import Flutterwave from 'flutterwave-vue-v3'
import VueHtmlToPaper from 'vue-html-to-paper';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

import 'animate.css';


const options = {
  name: '_blank',
  specs: [
    'fullscreen=yes',
    'titlebar=yes',
    'scrollbars=yes'
  ],
  styles: [
    'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css',
    'https://unpkg.com/kidlat-css/css/kidlat.css'
  ],
  timeout: 1000, // default timeout before the print window appears
  autoClose: true, // if false, the window will not close after printing
  windowTitle: window.document.title, // override the window title
}

export default {
  install(Vue) {
    Vue.use(require('vue-moment'));
    Vue.use( CKEditor );   
    Vue.use(globalComponents);
    Vue.use(globalDirectives);
    Vue.use(SidebarPlugin);
    Vue.use(NotificationPlugin);
    Vue.use(BootstrapVue);
    Vue.use(IconsPlugin);
    Vue.use(Flutterwave, {
      publicKey: 'FLWPUBK-56e9ef441a47fe2720edb3d7ae6e3d7a-X'
    });
    Vue.use(VueHtmlToPaper, options);
    Vue.use(VueHtmlToPaper);
  }
};