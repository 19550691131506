import axios from 'axios';



const addressinfo = {
    state: { 		
		stateitems : []
	},	
	mutations: {		
		states_success(state, payload){			
			state.stateitems = payload	
		}
	},
	actions: {        
		getstates({commit, rootState}){
			return new Promise((resolve, reject) => {				
				axios.get(`/Address/Getstates?accesskey=${rootState.auth.accesskey}`)
				.then(resp => resp.data)
				.then(respdata => {
					const payload = respdata;					
					commit('states_success',payload);					
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
		},				
		getlgas({rootState}, StateId){
			return new Promise((resolve, reject) => {				
				axios.get(`/Address/Getlgas?stateId=${StateId}&Username=${rootState.auth.username}&accesskey=${rootState.auth.accesskey}`)
				.then(resp => resp.data)
				.then(respdata => {									
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
		}
	}
}

export default addressinfo