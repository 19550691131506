const schoolStaffRoutes = [ 
  {
    path: '/user-profile',
    name: 'User Profile',
    component: () => import('@/views/UserProfile.vue'),
    meta: { user: ['Administrator', 'SchoolStaff', 'Student'] }
  },
  {
    path: '/school/environment-settings',
    name: 'Environment Settings',
    component: () => import('@/views/PortalSchool/Setting/Index.vue'),
    meta: { user: 'SchoolStaff' }
  },
  {
    path: '/school/session-settings',
    name: 'School Session Settings',
    component: () => import('@/views/PortalSchool/Setting/Component/Session.vue'),
    meta: { user: 'SchoolStaff' }
  },
  {
    path: '/school/term-settings',
    name: 'School Term Settings',
    component: () => import('@/views/PortalSchool/Setting/Component/Term.vue'),
    meta: { user: 'SchoolStaff' }
  },
  {
    path: '/school/class-settings',
    name: 'School Class Settings',
    component: () => import('@/views/PortalSchool/Setting/Component/Schclass.vue'),
    meta: { user: 'SchoolStaff' }
  },
  {
    path: '/school/option-settings',
    name: 'School Option Settings',
    component: () => import('@/views/PortalSchool/Setting/Component/Schoption.vue'),
    meta: { user: 'SchoolStaff' }
  },
  {
    path: '/school/arm-settings',
    name: 'School Arm Settings',
    component: () =>
      import('@/views/PortalSchool/Setting/Component/Scharm.vue'),
    meta: { user: 'SchoolStaff' }
  },
  {
    path: '/school/subject-settings',
    name: 'School Subject Settings',
    component: () =>
      import('@/views/PortalSchool/Setting/Component/Schsubject.vue'),
    meta: { user: 'SchoolStaff' }
  },
  {
    path: '/school/subject-topic-settings',
    name: 'School Subject Topic Settings',
    component: () =>
      import('@/views/PortalSchool/Setting/Component/Subjecttopic.vue'),
    meta: { user: 'SchoolStaff' }
  },
  {
    path: '/school/mock-exam-settings',
    name: 'School Mock Exam Settings',
    component: () =>
      import('@/views/PortalSchool/Setting/Component/Mock.vue'),
    meta: { user: 'SchoolStaff' }
  },
  {
    path: '/school/mock-exam-subject-settings',
    name: 'School Mock Exam Seettings',
    component: () =>
      import('@/views/PortalSchool/Setting/Component/Mocksubject.vue'),
    meta: { user: 'SchoolStaff' }
  },
  {
    path: '/school/psycomotor-skill-settings',
    name: 'School Psycomotor Skill Settings',
    component: () =>
      import(
        '@/views/PortalSchool/Setting/Component/Skill.vue'
      ),
    meta: { user: 'SchoolStaff' }
  },
  {
    path: '/school/conduct-settings',
    name: 'School Conduct Settings',
    component: () =>
      import('@/views/PortalSchool/Setting/Component/Conduct.vue'),
    meta: { user: 'SchoolStaff' }
  },
  {
    path: '/school/grade-scale-settings',
    name: 'School Grade Scale Settings',
    component: () =>
      import('@/views/PortalSchool/Setting/Component/Gradescale.vue'),
    meta: { user: 'SchoolStaff' }
  },
  {
    path: '/school/details',
    name: 'School Details',
    component: () =>
      import('@/views/PortalSchool/Setting/Component/Schooldetails.vue'),
    meta: { user: 'SchoolStaff' }
  },
  {
    path: '/school/registrar',
    name: 'School Registrar',
    component: () =>
      import('@/views/PortalSchool/Registrar/Index.vue'),
    meta: { user: 'SchoolStaff' }
  },
  {
    path: '/school/student-registration',
    name: 'School Student Registration',
    component: () =>
      import('@/views/PortalSchool/Registrar/Component/Student/Student.vue'),
    meta: { user: 'SchoolStaff' }
  },
  {
    path: '/school/deleted-student',
    name: 'School Deleted Student',
    component: () =>
      import('@/views/PortalSchool/Registrar/Component/Student/DeletedStudent.vue'),
    meta: { user: 'SchoolStaff' }
  },
  {
    path: '/school/student-promotion',
    name: 'School Student Promotion',
    component: () =>
      import('@/views/PortalSchool/Registrar/Component/Student/Promotion.vue'),
    meta: { user: 'SchoolStaff' }
  },
  {
    path: '/school/student-graduation',
    name: 'School Student Graduation',
    component: () =>
      import('@/views/PortalSchool/Registrar/Component/Student/Graduation.vue'),
    meta: { user: 'SchoolStaff' }
  },
  {
    path: '/school/student-alumni',
    name: 'School Student Alumni',
    component: () =>
      import('@/views/PortalSchool/Registrar/Component/Student/Alumni.vue'),
    meta: { user: 'SchoolStaff' }
  },
  {
    path: '/school/student-achievement',
    name: 'School Student Achievement',
    component: () =>
      import('@/views/PortalSchool/Registrar/Component/Student/Studentarchievement.vue'),
    meta: { user: 'SchoolStaff' }
  },
  {
    path: '/school/communication-note',
    name: 'School Communication Note',
    component: () =>
      import('@/views/PortalSchool/Registrar/Component/Student/Noteonstudent.vue'),
    meta: { user: 'SchoolStaff' }
  },
  {
    path: '/school/student-record',
    name: 'School Student Record',
    component: () =>
      import('@/views/PortalSchool/Student/Index.vue'),
    meta: { user: 'SchoolStaff' }
  },
  {
    path: '/school/student-class-attendance',
    name: 'School Student Class Attendance',
    component: () =>
      import('@/views/PortalSchool/Student/Component/Classattendance.vue'),
    meta: { user: 'SchoolStaff' }
  },
  {
    path: '/school/student-mark-record',
    name: 'School Student Mark Record',
    component: () =>
      import('@/views/PortalSchool/Student/Component/Markrecord.vue'),
    meta: { user: 'SchoolStaff' }
  },
  {
    path: '/school/student-conduct-record',
    name: 'School Student Conduct Record',
    component: () =>
      import('@/views/PortalSchool/Student/Component/Studentconduct.vue'),
    meta: { user: 'SchoolStaff' }
  },
  {
    path: '/school/student-psycomotor-skill-record',
    name: 'School Student Psycomotor Skill Record',
    component: () =>
      import('@/views/PortalSchool/Student/Component/Studentskill.vue'),
    meta: { user: 'SchoolStaff' }
  },
  {
    path: '/school/student-physical-development-assessment',
    name: 'School Student Physical Development Assessment',
    component: () =>
      import('@/views/PortalSchool/Student/Component/Physicaldev.vue'),
    meta: { user: 'SchoolStaff' }
  },
  {
    path: '/school/student-mock-record',
    name: 'School Student Mock Record',
    component: () =>
      import('@/views/PortalSchool/Student/Component/Mockrecord.vue'),
    meta: { user: 'SchoolStaff' }
  },
  {
    path: '/school/staff-registration',
    name: 'School Staff Registration',
    component: () =>
      import('@/views/PortalSchool/Registrar/Component/Staff/Staff.vue'),
    meta: { user: 'SchoolStaff' }
  },
  {
    path: '/school/deleted-staff',
    name: 'School Deleted Staff',
    component: () =>
      import('@/views/PortalSchool/Registrar/Component/Staff/DeletedStaff.vue'),
    meta: { user: 'SchoolStaff' }
  },
  {
    path: '/school/class-teacher',
    name: 'School Class Teacher',
    component: () =>
      import('@/views/PortalSchool/Registrar/Component/Staff/Classteacher.vue'),
    meta: { user: 'SchoolStaff' }
  },
  {
    path: '/school/subject-teacher',
    name: 'School Subject Teacher',
    component: () =>
      import('@/views/PortalSchool/Registrar/Component/Staff/Subjectteacher.vue'),
    meta: { user: 'SchoolStaff' }
  },
  {
    path: '/school/academic',
    name: 'School Academic',
    component: () =>
      import('@/views/PortalSchool/Academic/Index.vue'),
    meta: { user: 'SchoolStaff' }
  },
  {
    path: '/school/homework',
    name: 'School Homework',
    component: () =>
      import('@/views/PortalSchool/Academic/Component/Homework.vue'),
    meta: { user: 'SchoolStaff' }
  },
  {
    path: '/school/homework-answer',
    name: 'School Homework Answer',
    component: () =>
      import('@/views/PortalSchool/Academic/Component/Homeworkanswer.vue'),
    meta: { user: 'SchoolStaff' }
  },
  {
    path: '/school/exam-setup',
    name: 'School Exam Setup',
    component: () =>
      import('@/views/PortalSchool/Academic/Component/CBT/Questionheader.vue'),
    meta: { user: 'SchoolStaff' }
  },
  {
    path: '/school/exam-question-setup',
    name: 'School Exam Question Setup',
    component: () =>
      import('@/views/PortalSchool/Academic/Component/CBT/Question.vue'),
    meta: { user: 'SchoolStaff' }
  },
  {
    path: '/school/exam-student-answer',
    name: 'School Exam Student Answer',
    component: () =>
      import('@/views/PortalSchool/Academic/Component/CBT/Schcbtstudentanswer.vue'),
    meta: { user: 'SchoolStaff' }
  },
  {
    path: '/school/entrance-exam-setup',
    name: 'School Entrance Exam Setup',
    component: () =>
      import('@/views/PortalSchool/Academic/Component/CBT/Entranceheader.vue'),
    meta: { user: 'SchoolStaff' }
  },
  {
    path: '/school/entrance-exam-question-setup',
    name: 'School Entrance Exam Question Setup',
    component: () =>
      import('@/views/PortalSchool/Academic/Component/CBT/Entrancequestion.vue'),
    meta: { user: 'SchoolStaff' }
  },
  {
    path: '/school/entrance-exam-answer-setup',
    name: 'School Entrance Exam Answer Setup',
    component: () =>
      import('@/views/PortalSchool/Academic/Component/CBT/Schcbtstudentanswer.vue'),
    meta: { user: 'SchoolStaff' }
  },
  {
    path: '/school/entrance-exam-student-answer',
    name: 'School Entrance Exam Student Answer',
    component: () =>
      import('@/views/PortalSchool/Academic/Component/CBT/Entrancestudentanswer.vue'),
    meta: { user: 'SchoolStaff' }
  },
  {
    path: '/school/share-exam-question-setup',
    name: 'Share School Exam Question',
    component: () =>
      import('@/views/PortalSchool/Academic/Component/CBT/Sharequestion.vue'),
    meta: { user: 'SchoolStaff' }
  },
  {
    path: '/school/share-all-exam-question-setup',
    name: 'Share All School Exam Question',
    component: () =>
      import('@/views/PortalSchool/Academic/Component/CBT/Shareallquestion.vue'),
    meta: { user: 'SchoolStaff' }
  },
  {
    path: '/school/lesson-note',
    name: 'School Lesson Note',
    component: () =>
      import('@/views/PortalSchool/Academic/Component/LessonNote.vue'),
    meta: { user: 'SchoolStaff' }
  },
  {
    path: '/school/lesson-plan',
    name: 'School Lesson Plan',
    component: () =>
      import('@/views/PortalSchool/Academic/Component/LessonPlan.vue'),
    meta: { user: 'SchoolStaff' }
  },
  {
    path: '/school/class-note',
    name: 'School Class Note',
    component: () =>
      import('@/views/PortalSchool/Academic/ClassNote.vue'),
    meta: { user: 'SchoolStaff' }
  },
  {
    path: '/school/sms-sender',
    name: 'School SMS Sender',
    component: () =>
      import('@/views/PortalSchool/SMS/Index.vue'),
    meta: { user: 'SchoolStaff' }
  },
  {
    path: '/school/sms-to-guardian',
    name: 'School SMS to Guardian',
    component: () =>
      import('@/views/PortalSchool/SMS/Component/Guardiansms.vue'),
    meta: { user: 'SchoolStaff' }
  },
  {
    path: '/school/sms-to-staff',
    name: 'School SMS to Staff',
    component: () =>
      import('@/views/PortalSchool/SMS/Component/Staffsms.vue'),
    meta: { user: 'SchoolStaff' }
  },
  {
    path: '/school/sms-to-others',
    name: 'School SMS to Others',
    component: () =>
      import('@/views/PortalSchool/SMS/Component/Othersms.vue'),
    meta: { user: 'SchoolStaff' }
  },
  {
    path: '/school/track-sms',
    name: 'School Track SMS',
    component: () =>
      import('@/views/PortalSchool/SMS/Component/Tracksms.vue'),
    meta: { user: 'SchoolStaff' }
  },
  {
    path: '/school/report',
    name: 'School Report',
    component: () =>
      import('@/views/PortalSchool/Report/Index.vue'),
    meta: { user: 'SchoolStaff' }
  },
  {
    path: '/school/report-card',
    name: 'School Report Card',
    component: () =>
      import('@/views/PortalSchool/Report/Component/ReportCard.vue'),
    meta: { user: 'SchoolStaff' }
  },
  {
    path: '/school/mock-report',
    name: 'School Mock Report',
    component: () =>
      import('@/views/PortalSchool/Report/Component/Mockreport.vue'),
    meta: { user: 'SchoolStaff' }
  },
  {
    path: '/school/broadsheet-report',
    name: 'School Broadsheet Report',
    component: () =>
      import('@/views/PortalSchool/Report/Component/Broadsheet.vue'),
    meta: { user: 'SchoolStaff' }
  },
  {
    path: '/school/broadsheet-by-position-report',
    name: 'School Broadsheet by Position Report',
    component: () =>
      import('@/views/PortalSchool/Report/Component/BroadsheetPerPosition.vue'),
    meta: { user: 'SchoolStaff' }
  },
  {
    path: '/school/registered-student-report',
    name: 'School Registered Student Report',
    component: () =>
      import('@/views/PortalSchool/Report/Component/Student/Registeredstudent.vue'),
    meta: { user: 'SchoolStaff' }
  },
  {
    path: '/school/notifications',
    name: 'School Notifications',
    component: () =>
      import('@/views/PortalSchool/Notification/Index.vue'),
    meta: { user: 'SchoolStaff' }
  },
  {
    path: '/school/news',
    name: 'School News',
    component: () =>
      import('@/views/PortalSchool/Notification/Component/Newssetup.vue'),
    meta: { user: 'SchoolStaff' }
  },
  {
    path: '/school/read-news',
    name: 'School Read News',
    component: () =>
      import('@/views/PortalSchool/Notification/Component/ReadNews.vue'),
    meta: { user: 'SchoolStaff' }
  },
  {
    path: '/school/more-news',
    name: 'School More News',
    component: () =>
      import('@/views/PortalSchool/Notification/Component/MoreNews.vue'),
    meta: { user: 'SchoolStaff' }
  },
  {
    path: '/school/website-setup',
    name: 'School Website Setup',
    component: () =>
      import('@/views/PortalSchool/Schsite/Index.vue'),
    meta: { user: 'SchoolStaff' }
  },
  {
    path: '/school/website-header-banner',
    name: 'School Header Banner',
    component: () =>
      import('@/views/PortalSchool/Schsite/Component/Headerbanner.vue'),
    meta: { user: 'SchoolStaff' }
  },
  {
    path: '/school/entrance-registered',
    name: 'School Entrance Registered',
    component: () =>
      import('@/views/PortalSchool/Schsite/Component/Entranceregistration.vue'),
    meta: { user: 'SchoolStaff' }
  },
  {
    path: '/school/subscription',
    name: 'Subscription',
    component: () =>
      import('@/views/PortalSchool/Subscription/Index.vue'),
    meta: { user: 'SchoolStaff' }
  },
  {
    path: '/school/report-card-subscription',
    name: 'School Report Card Subscription',
    component: () =>
      import('@/views/PortalSchool/Subscription/Component/Reportcardsub.vue'),
    meta: { user: 'SchoolStaff' }
  },
  {
    path: '/school/cbt-report-card-subscription',
    name: 'School CBT & Report Subscription',
    component: () =>
      import('@/views/PortalSchool/Subscription/Component/ReportcardandCbt.vue'),
    meta: { user: 'SchoolStaff' }
  },
  {
    path: '/school/sms-subscription',
    name: 'School SMS Subscription',
    component: () =>
      import('@/views/PortalSchool/Subscription/Component/Sms.vue'),
    meta: { user: 'SchoolStaff' }
  },
  {
    path: '/school/finance',
    name: 'School Finance',
    component: () =>
      import('@/views/PortalSchool/Finance/Index.vue'),
    meta: { user: 'SchoolStaff' }
  },
  {
    path: '/school/school-bill-setup',
    name: 'School Bill Setup',
    component: () =>
      import('@/views/PortalSchool/Finance/Component/SchoolBillSetup.vue'),
    meta: { user: 'SchoolStaff' }
  },
  {
    path: '/school/school-bill',
    name: 'School Bill',
    component: () =>
      import('@/views/PortalSchool/Finance/Component/SchoolBill.vue'),
    meta: { user: 'SchoolStaff' }
  },
  {
    path: '/school/school-bill-payments',
    name: 'School Bill Payments',
    component: () =>
      import('@/views/PortalSchool/Finance/Component/SchoolBillPayment.vue'),
    meta: { user: 'SchoolStaff' }
  },
  {
    path: '/school/other-bill-payments',
    name: 'School Other Bill Payments',
    component: () =>
      import('@/views/PortalSchool/Finance/Component/OtherIncomePayment.vue'),
    meta: { user: 'SchoolStaff' }
  },
  {
    path: '/school/expenditure',
    name: 'School Expenditure',
    component: () =>
      import('@/views/PortalSchool/Finance/Component/OutcomePayment.vue'),
    meta: { user: 'SchoolStaff' }
  },
  {
    path: '/school/library',
    name: 'School Library',
    component: () =>
      import('@/views/PortalSchool/Library/Index.vue'),
    meta: { user: 'SchoolStaff' }
  },
  {
    path: '/school/library-category',
    name: 'School Library Category',
    component: () =>
      import('@/views/PortalSchool/Library/Component/LibraryCategory.vue'),
    meta: { user: 'SchoolStaff' }
  },
  {
    path: '/school/library-book',
    name: 'School Library Book',
    component: () =>
      import('@/views/PortalSchool/Library/Component/LibraryBook.vue'),
    meta: { user: 'SchoolStaff' }
  },
  {
    path: '/school/library-book-stock',
    name: 'School Library Book Stock',
    component: () =>
      import('@/views/PortalSchool/Library/Component/LibraryBookStock.vue'),
    meta: { user: 'SchoolStaff' }
  },
  {
    path: '/school/library-book-issue',
    name: 'School Library Book Issue',
    component: () =>
      import('@/views/PortalSchool/Library/Component/LibraryBookIssue.vue'),
    meta: { user: 'SchoolStaff' }
  },
  {
    path: '/school/library-book-retured',
    name: 'School Library Book Retured',
    component: () =>
      import('@/views/PortalSchool/Library/Component/LibraryBookReturn.vue'),
    meta: { user: 'SchoolStaff' }
  },
  {
    path: '/school/library-book-stock-summary',
    name: 'School Library Book Stock Summary',
    component: () =>
      import('@/views/PortalSchool/Library/Component/LibraryBookStockSummary.vue'),
    meta: { user: 'SchoolStaff' }
  },
  {
    path: '/school/hostel',
    name: 'School Hostel',
    component: () =>
      import('@/views/PortalSchool/Hostel/Index.vue'),
    meta: { user: 'SchoolStaff' }
  },
  {
    path: '/school/hostel-registration',
    name: 'School Hostel Registration',
    component: () =>
      import('@/views/PortalSchool/Hostel/Component/Hostel.vue'),
    meta: { user: 'SchoolStaff' }
  },
  {
    path: '/school/hostel-floor',
    name: 'School Hostel Floor',
    component: () =>
      import('@/views/PortalSchool/Hostel/Component/HostelFloor.vue'),
    meta: { user: 'SchoolStaff' }
  },
  {
    path: '/school/hostel-room',
    name: 'School Hostel Room',
    component: () =>
      import('@/views/PortalSchool/Hostel/Component/HostelRoom.vue'),
    meta: { user: 'SchoolStaff' }
  },
  {
    path: '/school/hostel-bed',
    name: 'School Hostel Bed',
    component: () =>
      import('@/views/PortalSchool/Hostel/Component/HostelBed.vue'),
    meta: { user: 'SchoolStaff' }
  },
  {
    path: '/school/hostel-student-bed',
    name: 'School Hostel Student Bed',
    component: () =>
      import('@/views/PortalSchool/Hostel/Component/HostelStudentBed.vue'),
    meta: { user: 'SchoolStaff' }
  },
  {
    path: '/tutorial/exam-setup',
    name: 'Tutorial Center Exam Setup',
    component: () =>
      import('@/views/PortalSchool/Academic/Component/CBT/TcQuestionHeader.vue'),
    meta: { user: 'SchoolStaff' }
  },
  {
    path: '/tutorial/exam-question-setup',
    name: 'Tutorial Center Exam Question Setup',
    component: () =>
      import('@/views/PortalSchool/Academic/Component/CBT/TcQuestion.vue'),
    meta: { user: 'SchoolStaff' }
  },
  {
    path: '/tutorial/share-exam-question',
    name: 'Share Tutorial Center Exam Question',
    component: () =>
      import('@/views/PortalSchool/Academic/Component/CBT/TcSharequestion.vue'),
    meta: { user: 'SchoolStaff' }
  },
  {
    path: '/tutorial/share-all-exam-question',
    name: 'Share All Tutorial Center Exam Question',
    component: () =>
      import('@/views/PortalSchool/Academic/Component/CBT/TcShareallquestion.vue'),
    meta: { user: 'SchoolStaff' }
  },

]

export default schoolStaffRoutes
