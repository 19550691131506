import axios from 'axios';

const classteacher = {
   
	actions: {
        getclassteacher({rootState}){
			return new Promise((resolve, reject) => {				
				axios.get(`/Classteacher/GetClassTeacher?Username=${rootState.auth.username}&accesskey=${rootState.auth.accesskey}`)
				.then(resp => resp.data)
				.then(respdata => {	                   								
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
		},		
		GetClassTeacherByStaffId({rootState},StaffId){
			return new Promise((resolve, reject) => {				
				axios.get(`/Classteacher/GetClassTeacherByStaffId?StaffId=${StaffId}&Username=${rootState.auth.username}&accesskey=${rootState.auth.accesskey}`)
				.then(resp => resp.data)
				.then(respdata => {	                  							
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
		},			
		deleteclassteacher({rootState},Id){
			return new Promise((resolve, reject) => {				
				axios.get(`/Classteacher/DeleteClassTeacher?id=${Id}&Username=${rootState.auth.username}&accesskey=${rootState.auth.accesskey}`)
				.then(resp => resp.data)
				.then(respdata => {	
                   
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
		},				
		Processclassteacher(context, userdata){
			return new Promise((resolve, reject) => {				
				axios({url: '/Classteacher/ProcessClassTeacher', data: userdata, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {	                  									
					resolve(respdata)
				})
				.catch(err => {
					reject(err)
				})
			})
        },        	
	}
}
export default classteacher