import axios from 'axios';

const Community = {
    namespaced: true,    
    actions: {
        CreateCommunity({ rootState }, payload) {
            return new Promise((resolve, reject) => {  
                const headers = {
                    Authorization: `bearer ${rootState.auth.token}`
                };                      
                axios.post(`${process.env.VUE_APP_ENV_API_URL}Community/CreateCommunity`, payload, { headers })
                    .then(resp => resp.data)
                    .then(data => {                       
                        resolve(data)
                    })
                    .catch(err => {                       
                        reject(err)
                    })
            })
        },    
        UpdateCommunity({ rootState }, payload) {
            return new Promise((resolve, reject) => {  
                const headers = {
                    Authorization: `bearer ${rootState.auth.token}`
                };                      
                axios.post(`${process.env.VUE_APP_ENV_API_URL}Community/UpdateCommunity`, payload, { headers })
                    .then(resp => resp.data)
                    .then(data => {                       
                        resolve(data)
                    })
                    .catch(err => {                       
                        reject(err)
                    })
            })
        },  
        GetCommunities({ rootState }) {
            return new Promise((resolve, reject) => {  
                const headers = {
                    Authorization: `bearer ${rootState.auth.token}`
                };                    
                axios.get(`${process.env.VUE_APP_ENV_API_URL}Community/GetCommunitiesForPortal`, { headers })
                    .then(resp => resp.data)
                    .then(data => {                       
                        resolve(data)
                    })
                    .catch(err => {                       
                        reject(err)
                    })
            })
        }, 
        GetCommunityById({ rootState },Id) {
            return new Promise((resolve, reject) => {  
                const headers = {
                    Authorization: `bearer ${rootState.auth.token}`
                };                    
                axios.get(`${process.env.VUE_APP_ENV_API_URL}Community/GetCommunityByIdForPortal?Id=${Id}`, { headers })
                    .then(resp => resp.data)
                    .then(data => {                       
                        resolve(data)
                    })
                    .catch(err => {                       
                        reject(err)
                    })
            })
        },          
    },
}

export default Community