import axios from 'axios';

const examquestion = {
   
	actions: {        			
		verifyaccesscode({rootState},accesscode){
			return new Promise((resolve, reject) => {				
				axios.get(`/Schexam/Verifyaccesscode?AccessCode=${accesscode}&Username=${rootState.auth.username}&accesskey=${rootState.auth.accesskey}`)
				.then(resp => resp.data)
				.then(respdata => {	                   
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
		},			
		GetQuestionsforExam({rootState}, {HeaderId,Maxno}){
			return new Promise((resolve, reject) => {				
				axios.get(`/Schexam/GetQuestionsforExam?HeaderId=${HeaderId}&Maxno=${Maxno}&Username=${rootState.auth.username}&accesskey=${rootState.auth.accesskey}`)
				.then(resp => resp.data)
				.then(respdata => {	                   								
					resolve(respdata)
				})
				.catch(err => {
					reject(err)
				})
			})
		},
		GetSelectedQuestionsforExam({rootState}, HeaderId){
			return new Promise((resolve, reject) => {				
				axios.get(`/Schexam/GetSelectedQuestionsforExam?HeaderId=${HeaderId}&Username=${rootState.auth.username}&accesskey=${rootState.auth.accesskey}`)
				.then(resp => resp.data)
				.then(respdata => {	                   								
					resolve(respdata)
				})
				.catch(err => {
					reject(err)
				})
			})
		},
		getschquestionforexambyid({rootState},{Id, HeaderId}){
			return new Promise((resolve, reject) => {				
				axios({url: '/Schexam/Getschquestionforexambyid', data: {id: Id, idd: HeaderId, searchitem: null, username : rootState.auth.username, accesskey : rootState.auth.accesskey}, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {	                   
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
		},
		processsanswer(context, userdata){
			return new Promise((resolve, reject) => {				
				axios({url: '/Schexam/Processsanswer', data: userdata, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {	                   								
					resolve(respdata)
				})
				.catch(err => {
					reject(err)
				})
			})
		},	
		schexamresult({rootState}, Id){
			return new Promise((resolve, reject) => {				
				axios({url: '/Schexam/Schexamresult', data: {id: Id, searchitem: null, username : rootState.auth.username, accesskey : rootState.auth.accesskey}, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {	                   								
					resolve(respdata)
				})
				.catch(err => {
					reject(err)
				})
			})
		},	
		RetakeExamforStudent({rootState},{HeaderId,StudentId}){
			return new Promise((resolve, reject) => {				
				axios.get(`/Schexam/RetakeExamforStudent?HeaderId=${HeaderId}&StudentId=${StudentId}&Username=${rootState.auth.username}&accesskey=${rootState.auth.accesskey}`)
				.then(resp => resp.data)
				.then(respdata => {	                   
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
		}, 
	}
}
export default examquestion