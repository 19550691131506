import axios from 'axios';

const schsubjecttopic = {
    state: {  
		schsubjecttopic : []
	},	
	mutations: {		
		schsubjecttopic_success(state, payload){			
			state.schsubjecttopic = payload	
		}	
	},
	actions: {
        getsubjecttopic({rootState},SubjectId){
			return new Promise((resolve, reject) => {				
				axios({url: '/Subjecttopic/Getsubjecttopic', data: {id: SubjectId, searchitem: null, username : rootState.auth.username, accesskey : rootState.auth.accesskey}, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {									
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
		},		
		getsubjecttopicsearch({rootState},{Id,searchitem}){
			return new Promise((resolve, reject) => {				
				axios({url: '/Subjecttopic/Getsubjecttopicsearch', data: {id: Id, searchitem: searchitem, username : rootState.auth.username, accesskey : rootState.auth.accesskey}, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {									
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
		},		
		
        getsubjecttopicbyid({rootState},{Id,SubjectId}){
			return new Promise((resolve, reject) => {				
				axios({url: '/Subjecttopic/Getsubjecttopicbyid', data: {id: Id, idd: SubjectId, searchitem: null, username : rootState.auth.username, accesskey : rootState.auth.accesskey}, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {									
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
		},		
		processsubjecttopic(context, userdata){
			return new Promise((resolve, reject) => {				
				axios({url: '/Subjecttopic/Processsubjecttopic', data: userdata, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {
										
					resolve(respdata)
				})
				.catch(err => {
					reject(err)
				})
			})
		}
	}
}

export default schsubjecttopic