import axios from 'axios';

const physicaldev = {
    state: {  
		physicaldevs : []
	},	
	mutations: {		
		physicaldevs_success(state, payload){			
			state.physicaldevs = payload	
		}	
	},
	actions: {
        getphysicaldev(context, userdata){
			return new Promise((resolve, reject) => {				
				axios({url: '/Physicaldevelopment/Getphysicaldev', data: userdata, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {						
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
		},			
		getphysicaldevbyid(context, userdata){
			return new Promise((resolve, reject) => {				
				axios({url: '/Physicaldevelopment/Getphysicaldevbyid', data: userdata, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {									
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
		},						
		physicaldevregistration(context, userdata){
			return new Promise((resolve, reject) => {				
				axios({url: '/Physicaldevelopment/Registerphysicaldev', data: userdata, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {									
					resolve(respdata)
				})
				.catch(err => {
					reject(err)
				})
			})
        },        
	}
}

export default physicaldev