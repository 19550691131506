import axios from 'axios';

const SchoolStaff = {
    namespaced: true,  
    state: {       
        schoolStaff: JSON.stringify(localStorage.getItem('#####')),    
    },  
    mutations: {        
        Save_School_Staff_Info (state, payload) {
            localStorage.setItem('#####', payload)
            state.schoolStaff=payload
          },
    },
    getters: {       
        _schoolStaff: state => state.schoolStaff,       
    },
    actions: {       
        GetLoginStaffDetails({ commit, rootState }) {
            return new Promise((resolve, reject) => {
                const headers = {
                    Authorization: `bearer ${rootState.auth.token}`
                };               
                axios.get(`${process.env.VUE_APP_ENV_API_URL}PortalSchoolStaff/GetLoginStaffDetails`, { headers })
                    .then(resp => resp.data)
                    .then(returnData => {
                        const data=returnData.data;                           
                        commit('Save_School_Staff_Info',data )
                        resolve(returnData)
                    })
                    .catch(err => {                          
                        reject(err)
                    })
            })
        },       
    },
}

export default SchoolStaff