<template>
  <div class="wrapper" :class="{ 'nav-open': $sidebar.showSidebar }" v-if="accessDetails">
    <InternetAlert />
    <StaffNavbar v-if="accessDetails.isSchoolStaff == true"></StaffNavbar>
    <StudentNavbar
      v-if="
        accessDetails.isSchoolStudent == true ||
          accessDetails.isStudentGuardian == true
      "
    ></StudentNavbar>
    <AdminNavbar
      v-if="
        accessDetails.isSuperAdmin == true ||
          accessDetails.isAdmin == true ||
          accessDetails.isSupportAdmin == true ||
           accessDetails.isAffliateMarketer == true
      "
    ></AdminNavbar>

    <div class="main-content" :data="sidebarBackground">
      <dashboard-navbar></dashboard-navbar>

      <div @click="toggleSidebar">
        <fade-transition :duration="200" origin="center top" mode="out-in">
          <!-- your content here -->

          <router-view> </router-view>
        </fade-transition>

        <content-footer v-if="!$route.meta.hideFooter"></content-footer>
      </div>
    </div>
  </div>
</template>
<script>
import DashboardNavbar from "./DashboardNavbar.vue";
import ContentFooter from "./ContentFooter.vue";
import StaffNavbar from "./StaffNavbar.vue";
import StudentNavbar from "./StudentNavbar.vue";
import AdminNavbar from "./AdminNavbar.vue";
import { FadeTransition } from "vue2-transitions";
import InternetAlert from "../views/InternetAlert.vue";
export default {  
  components: {
    DashboardNavbar,
    ContentFooter,
    StaffNavbar,
    StudentNavbar,
    AdminNavbar,
    InternetAlert,
    FadeTransition,
  },
  data() {
    return {
      accessDetails:null,
      sidebarBackground: "vue", //vue|blue|orange|green|red|primary
    };
  },
  methods: {
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    },
  },
  mounted() {
    this.accessDetails = this.$store.getters['auth/_user'];
  },
};
</script>
<style lang="scss"></style>
