import axios from 'axios';

const promotion = {
	namespaced: true,
	actions: {       					
		Promotestudent(context, userdata){
			return new Promise((resolve, reject) => {				
				axios.post('/Student/Promotestudent',userdata)
				.then(resp => resp.data)
				.then(respdata => {									
					resolve(respdata)
				})
				.catch(err => {
					reject(err)
				})
			})
        },        
	}
}

export default promotion