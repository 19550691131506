import axios from 'axios';

const studentskillmid = {
    state: {  
		studentskillmids : []
	},	
	mutations: {		
		studentskillmids_success(state, payload){			
			state.studentskillmids = payload	
		}	
	},
	actions: {
        getstudentpsycomotormid(context, userdata){
			return new Promise((resolve, reject) => {				
				axios({url: '/Studentpsycomotormid/Getstudentpsycomotormid', data: userdata, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {						
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
		},			
		getstudentpsycomotormidbyid(context, userdata){
			return new Promise((resolve, reject) => {				
				axios({url: '/Studentpsycomotormid/Getstudentpsycomotormidbyid', data: userdata, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {									
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
		},						
		studentpsycomotormidregistration(context, userdata){
			return new Promise((resolve, reject) => {				
				axios({url: '/Studentpsycomotormid/Registerstudentpsycomotormid', data: userdata, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {									
					resolve(respdata)
				})
				.catch(err => {
					reject(err)
				})
			})
        },        
	}
}

export default studentskillmid