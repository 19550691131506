import axios from 'axios';

const markrecordmid = {
    state: {  
		markrecordmids : []
	},	
	mutations: {		
		markrecordmids_success(state, payload){			
			state.markrecords = payload	
		}	
	},
	actions: {
        getmarkrecordmid(context, userdata){
			return new Promise((resolve, reject) => {				
				axios({url: '/Markrecordmid/Getmarkrecordmid', data: userdata, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {						
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
		},			
		getmarkrecordmidbyid(context, userdata){
			return new Promise((resolve, reject) => {				
				axios({url: '/Markrecordmid/Getmarkrecordmidbyid', data: userdata, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {									
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
		},						
		markrecordmidregistration(context, userdata){
			return new Promise((resolve, reject) => {				
				axios({url: '/Markrecordmid/Registermarkrecordmid', data: userdata, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {									
					resolve(respdata)
				})
				.catch(err => {
					reject(err)
				})
			})
        },        
	}
}

export default markrecordmid