import axios from 'axios';

const news = {
    
	actions: {
        processnewssch(context, UserData){
			return new Promise((resolve, reject) => {				
				axios({url: '/News/Processnewssch', data: UserData, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {	                   									
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
		},				
		getnewssch({rootState}){
			return new Promise((resolve, reject) => {				
				axios({url: '/News/Getnewssch', data: {id: 1, searchitem: null, username : rootState.auth.token, accesskey : rootState.auth.accesskey}, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {	                   
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
		},		
		getnewsschonly({rootState}){
			return new Promise((resolve, reject) => {				
				axios({url: '/News/GetNewsSchOnly', data: {id: 1, searchitem: null, username : rootState.auth.username, accesskey : rootState.auth.accesskey}, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {	                   
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
		},		
		getnewsschbyheading({rootState},heading){
			return new Promise((resolve, reject) => {				
				axios.get(`/News/GetNewsSchByHeading?heading=${heading}&username=${rootState.auth.username}&accessKey=${rootState.auth.accesskey}`)
				.then(resp => resp.data)
				.then(respdata => {					
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
        },		
		getnewsschbyid({rootState},Id){
			return new Promise((resolve, reject) => {				
				axios({url: '/News/Getnewsschbyid', data: {id: Id, searchitem: null, username : rootState.auth.username, accesskey : rootState.auth.accesskey}, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {	                   
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
		},				
		getnewsschbysearch({rootState},item){
			return new Promise((resolve, reject) => {				
				axios({url: '/News/Getnewsschbysearch', data: {id: 1, searchitem: item, username : rootState.auth.username, accesskey : rootState.auth.accesskey}, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {	                   
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
        },
		getnewsschtop10({rootState}){
			return new Promise((resolve, reject) => {				
				axios.get(`/News/GetNewsSchTop10?username=${rootState.auth.username}&accessKey=${rootState.auth.accesskey}`)
				.then(resp => resp.data)
				.then(respdata => {					
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
        },
        processnews(context, UserData){
			return new Promise((resolve, reject) => {				
				axios({url: '/News/Processnews', data: UserData, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {	                   									
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
		},				
		getnews({rootState}){
			return new Promise((resolve, reject) => {				
				axios({url: '/News/Getnews', data: {id: 1, searchitem: null, username : rootState.auth.username, accesskey : rootState.auth.accesskey}, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {	                   
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
		},			
		getnewsbyid({rootState},Id){
			return new Promise((resolve, reject) => {				
				axios({url: '/News/Getnewsbyid', data: {id: Id, searchitem: null, username : rootState.auth.username, accesskey : rootState.auth.accesskey}, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {	                   
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
		},				
		getnewsbysearch({rootState},item){
			return new Promise((resolve, reject) => {				
				axios({url: '/News/Getnewsbysearch', data: {id: 1, searchitem: item, username : rootState.auth.username, accesskey : rootState.auth.accesskey}, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {	                   
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
        },
		getnewsforexternal({rootState}){
			return new Promise((resolve, reject) => {				
				axios.get(`/News/GetNewsforExternal?accessKey=${rootState.auth.accesskey}`)
				.then(resp => resp.data)
				.then(respdata => {					
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
        },	
		getnewsforexternalbyid({rootState},Id){
			return new Promise((resolve, reject) => {				
				axios.get(`/News/GetNewsforExternalById?accessKey=${rootState.auth.accesskey}&Id=${Id}`)
				.then(resp => resp.data)
				.then(respdata => {					
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
        },	
		getnewsforexternaltop10({rootState}){
			return new Promise((resolve, reject) => {				
				axios.get(`/News/GetNewsforExternalBy10?accessKey=${rootState.auth.accesskey}`)
				.then(resp => resp.data)
				.then(respdata => {					
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
        },
		getnewsforexternalbyheading({rootState},heading){
			return new Promise((resolve, reject) => {				
				axios.get(`/News/GetNewsforExternalByHeading?heading=${heading}&accessKey=${rootState.auth.accesskey}`)
				.then(resp => resp.data)
				.then(respdata => {					
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
        },	
		processnewscomment(context, UserData){
			return new Promise((resolve, reject) => {				
				axios({url: '/News/ProcessNewsComment', data: UserData, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {	                   									
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
		},	
		getnewscomment({rootState},NewsId){
			return new Promise((resolve, reject) => {				
				axios.get(`/News/GetNewsComment?NewsId=${NewsId}&accessKey=${rootState.auth.accesskey}`)
				.then(resp => resp.data)
				.then(respdata => {					
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
        },			
	}
}

export default news