import axios from 'axios';

const mockreport = {    
	actions: {        
        getmockreport({rootState}, {MockId,SessionId,StudId}){
			return new Promise((resolve, reject) => {				
				axios.get(`/Mockreport/Getmockreport?MockId=${MockId}&SessionId=${SessionId}&StudId=${StudId}&SessionId&Username=${rootState.auth.username}&accesskey=${rootState.auth.accesskey}`)
				.then(resp => resp.data)
				.then(respdata => {						
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
		},							
		Processmockcommentbyteacher(context, userdata){
			return new Promise((resolve, reject) => {				
				axios({url: '/Mockreport/Processmockcommentbyteacher', data: userdata, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {									
					resolve(respdata)
				})
				.catch(err => {
					reject(err)
				})
			})
        },    
        Processmockcommentbyheadteacher(context, userdata){
			return new Promise((resolve, reject) => {				
				axios({url: '/Mockreport/Processmockcommentbyheadteacher', data: userdata, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {									
					resolve(respdata)
				})
				.catch(err => {
					reject(err)
				})
			})
        },  
        getmockcomment(context, userdata){
			return new Promise((resolve, reject) => {				
				axios({url: '/Mockreport/Getmockcomment', data: userdata, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {									
					resolve(respdata)
				})
				.catch(err => {
					reject(err)
				})
			})
        },                
	}
}

export default mockreport