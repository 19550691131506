import axios from 'axios';

const smsSender = {
    state: {  
		sms : []
	},	
	mutations: {		
		sms_success(state, payload){			
			state.sms = payload	
		}	
	},
	actions: {   
		getsmsstaff({rootState}){
			return new Promise((resolve, reject) => {				
				axios({url: '/SMSSender/Getsmsstaff', data: {id: 1, searchitem: null, username : rootState.auth.username, accesskey : rootState.auth.accesskey}, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {	                   
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
        },    			
		getsmsguardianbyschool({rootState}){
			return new Promise((resolve, reject) => {				
				axios({url: '/SMSSender/Getsmsguardianbyschool', data: {id: 1, searchitem: null, username : rootState.auth.username, accesskey : rootState.auth.accesskey}, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {	                   
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
        },
        getsmsguardianbyclass({rootState},ClassId){
			return new Promise((resolve, reject) => {				
				axios({url: '/SMSSender/Getsmsguardianbyclass', data: {id: ClassId, searchitem: null, username : rootState.auth.username, accesskey : rootState.auth.accesskey}, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {	                   
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
        },	
        getsmsguardianbyoption({rootState},OptionId){
			return new Promise((resolve, reject) => {				
				axios({url: '/SMSSender/Getsmsguardianbyoption', data: {id: OptionId, searchitem: null, username : rootState.auth.username, accesskey : rootState.auth.accesskey}, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {	                   
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
        },	
        getsmsguardianbyarm({rootState},ArmId){
			return new Promise((resolve, reject) => {				
				axios({url: '/SMSSender/Getsmsguardianbyarm', data: {id: ArmId, searchitem: null, username : rootState.auth.username, accesskey : rootState.auth.accesskey}, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {	                   
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
        },	
        getsmsguardianbystudent({rootState},ArmId){
			return new Promise((resolve, reject) => {				
				axios({url: '/SMSSender/Getsmsguardianbystudent', data: {id: ArmId, searchitem: null, username : rootState.auth.username, accesskey : rootState.auth.accesskey}, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {	                   
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
        },	
        deletesmstempbyid({rootState},Id){
			return new Promise((resolve, reject) => {				
				axios({url: '/SMSSender/Deletesmstempbyid', data: {id: Id, searchitem: null, username : rootState.auth.username, accesskey : rootState.auth.accesskey}, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {	                   
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
		},					
		processsms(context, userdata){
			return new Promise((resolve, reject) => {				
				axios({url: '/SMSSender/Processsms', data: userdata, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {	                   								
					resolve(respdata)
				})
				.catch(err => {
					reject(err)
				})
			})
		},
		processsmsother(context, userdata){
			return new Promise((resolve, reject) => {				
				axios({url: '/SMSSender/Processsmsother', data: userdata, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {	                   									
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
        },		
        processsmsadmin(context, userdata){
			return new Promise((resolve, reject) => {				
				axios({url: '/SMSSender/Processsmsadmin', data: userdata, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {	                   									
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
		},	
		tracksms({rootState}){
			return new Promise((resolve, reject) => {				
				axios({url: '/SMSSender/Tracksms', data: {id: 1, searchitem: null, username : rootState.auth.username, accesskey : rootState.auth.accesskey}, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {	                   
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
        },  			
		
	}
}

export default smsSender