import axios from "axios";

const LessonNote = {
 // namespaced: true,
  actions: {
    ProcessLessonNote(context, userdata) {
      return new Promise((resolve, reject) => {      
        axios
          .post(
            `/LessonNote/ProcessLessonNote`,
            userdata
          )
          .then((resp) => resp.data)
          .then((respdata) => {
            resolve(respdata);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    ApproveLessonNote({rootState}, LessonNoteId) {
      return new Promise((resolve, reject) => {       
        axios
          .post(
            `/LessonNote/ApproveLessonNote?LessonNoteId=${LessonNoteId}&username=${rootState.auth.username}&accesskey=${rootState.auth.accesskey}`
          )
          .then((resp) => resp.data)
          .then((respdata) => {
            resolve(respdata);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    DisapproveLessonNote({ rootState }, {LessonNoteId,Comment}) {
      return new Promise((resolve, reject) => {       
        axios
          .get(
            `/LessonNote/DisapproveLessonNote?LessonNoteId=${LessonNoteId}&Comment=${Comment}&username=${rootState.auth.username}&accesskey=${rootState.auth.accesskey}`
          )
          .then((resp) => resp.data)
          .then((respdata) => {
            resolve(respdata);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    GetLessonNotes({ rootState },{SessionId,TermId}) {
      return new Promise((resolve, reject) => {       
        axios
          .get(
            `/LessonNote/GetLessonNotes?SessionId=${SessionId}&TermId=${TermId}&username=${rootState.auth.username}&accesskey=${rootState.auth.accesskey}`
          )
          .then((resp) => resp.data)
          .then((respdata) => {
            resolve(respdata);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    GetLessonNotesPending({ rootState },{SessionId,TermId}) {
      return new Promise((resolve, reject) => {       
        axios
          .get(
            `/LessonNote/GetLessonNotesPending?SessionId=${SessionId}&TermId=${TermId}&username=${rootState.auth.username}&accesskey=${rootState.auth.accesskey}`
          )
          .then((resp) => resp.data)
          .then((respdata) => {
            resolve(respdata);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    GetLessonNotesApproved({ rootState },{SessionId,TermId}) {
      return new Promise((resolve, reject) => {       
        axios
          .get(
            `/LessonNote/GetLessonNotesApproved?SessionId=${SessionId}&TermId=${TermId}&username=${rootState.auth.username}&accesskey=${rootState.auth.accesskey}`
          )
          .then((resp) => resp.data)
          .then((respdata) => {
            resolve(respdata);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    GetLessonNotesDisApproved({ rootState },{SessionId,TermId}) {
      return new Promise((resolve, reject) => {       
        axios
          .get(
            `/LessonNote/GetLessonNotesDisApproved?SessionId=${SessionId}&TermId=${TermId}&username=${rootState.auth.username}&accesskey=${rootState.auth.accesskey}`
          )
          .then((resp) => resp.data)
          .then((respdata) => {
            resolve(respdata);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    GetByIdLessonNote({ rootState },Id) {
      return new Promise((resolve, reject) => {       
        axios
          .get(
            `/LessonNote/GetByIdLessonNote?Id=${Id}&username=${rootState.auth.username}&accesskey=${rootState.auth.accesskey}`
          )
          .then((resp) => resp.data)
          .then((respdata) => {
            resolve(respdata);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },   
    GetBySubjectLessonNote({ rootState },{SubjectId,SessionId,TermId}) {
      return new Promise((resolve, reject) => {       
        axios
          .get(
            `/LessonNote/GetBySubjectLessonNote?SubjectId=${SubjectId}&SessionId=${SessionId}&TermId=${TermId}&username=${rootState.auth.username}&accesskey=${rootState.auth.accesskey}`
          )
          .then((resp) => resp.data)
          .then((respdata) => {
            resolve(respdata);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },   
  },
};

export default LessonNote;
