import DashboardLayout from "@/layout/DashboardLayout";
import AuthLayout from "@/layout/AuthLayout";
// import HomeLayout from "@/layout/HomeLayout";
import HomeRoutes from '@/routers/homeRouter';
import AdminRoutes from '@/routers/adminRouter';
import SchoolStaffRouter from '@/routers/schoolStaffRouter';
import StudentRouter from '@/routers/studentRouter';

const routes = [
  // HOME LAYOUT
  {
    path: '/',
    redirect:'login',
    name: 'Home Layout',
    component: AuthLayout, 
    children: HomeRoutes
  },

  // ADMIN
  {
    path: '/',
    name: 'Admin Layout',
    component: DashboardLayout,
    children: AdminRoutes
  },

  // School Staff Router
  {
    path: '/',
    name: 'School Staff Router',
    component: DashboardLayout,
    children: SchoolStaffRouter
  },

  // Student Router
  {
    path: '/',
    name: 'Student Router',
    component: DashboardLayout,
    children: StudentRouter
  },

  // // NOT FOUND
  // {
  //   path: '/:pathMatch(.*)*',
  //   name: 'Not Found',
  //   redirect: '/not-found',
  //   component: () => import('@/views/NotFound.vue')
  // }
]

export default routes